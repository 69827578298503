import React, { useRef, useMemo, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { Pressable, xcss, Text, Flex } from '@atlaskit/primitives';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import { token } from '@atlaskit/tokens';
import AvatarGroup from '@atlaskit/avatar-group';
import Button from '@atlaskit/button/new';

import type { CommentType, ReplyData } from '@confluence/comments-data';
import { CommentType as CommentTypeEnum } from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';
import { WithCurvedBranchingStyle } from '@confluence/inline-comments-common/entry-points/components';
import { useCommentsPanel } from '@confluence/comments-panel-utils';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { ViewReplyOptions } from '../helper/commentThreadHelper';

const repliesTogglerStyles = {
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	left: 0,
};

const repliesTogglerBranchingStyle = css({
	position: 'relative',
	'&::before': {
		content: "''",
		position: 'absolute',
		left: 0,
		borderLeft: `1.5px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		top: 0,
		height: '60px',
	},
});

const repliesTogglerBranchingStyleWithoutReply = css({
	'&::before': {
		height: '20px',
	},
});

const replyWrapperWithSpacingStyles = {
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	left: 0,
};

const unreadIndicatorStyle = xcss({
	alignItems: 'center',
	gap: 'space.075',
});

const pressableStyles = xcss({
	color: 'color.text.subtle',
	backgroundColor: 'color.background.neutral.subtle',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	borderRadius: 'border.radius.100',
	userSelect: 'none',
	fontWeight: token('font.weight.medium'),

	':hover': {
		cursor: 'pointer',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});
// This snippet is being used in CommentBody.tsx
const dotStyles = css({
	height: token('space.100'),
	width: token('space.100'),
	backgroundColor: token('color.background.brand.bold'),
	borderRadius: '50%',
	margin: token('space.050'),
});

const i18n = defineMessages({
	commentsPanelHideRepliesText: {
		id: 'comments-panel.comment-thread.hide.replies',
		defaultMessage: 'Hide replies',
		description: 'Text for hiding replies',
	},
	commentsPanelHideReplyText: {
		id: 'comments-panel.comment-thread.hide.reply',
		defaultMessage: 'Hide reply',
		description: 'Text for hiding a reply',
	},
	commentsPanelShowMoreRepliesText: {
		id: 'comments-panel.comment-thread.show.more.replies',
		defaultMessage: `Show {numOfHiddenReplies, plural, one {# more reply} other {# more replies}}`,
		description: 'Text for showing more replies',
	},
	commentsPanelShowReplyText: {
		id: 'comments-panel.comment-thread.show.reply',
		defaultMessage: 'Show reply',
		description: 'Text for showing a single hidden reply',
	},
});

type ProfilePictureInfo = {
	displayName: string;
	profilePicturePath: string | undefined;
};

const withLeftMargin = css({
	marginLeft: token('space.150'),
});

export const RepliesToggler = ({
	replies,
	setVisibleReplies,
	unreadReplyIds,
	setReplyView,
	threadKey,
	isAllRepliesView,
	numOfHiddenReplies,
	commentType,
	parentCommentId,
	isParentOpen,
	canAddComments,
	shouldShowLatestReplies,
	setShouldShowLatestReplies,
	latestReplyIds,
}: {
	replies: ReplyData[];
	setVisibleReplies: React.Dispatch<React.SetStateAction<ReplyData[]>>;
	unreadReplyIds: string[];
	setReplyView: (option: ViewReplyOptions) => void;
	threadKey: string;
	isAllRepliesView: boolean;
	numOfHiddenReplies: React.MutableRefObject<number>;
	commentType: CommentType;
	parentCommentId: string;
	isParentOpen: boolean;
	canAddComments: boolean;
	shouldShowLatestReplies: boolean;
	setShouldShowLatestReplies: (shouldShowLatestReplies: boolean) => void;
	latestReplyIds: Set<string>;
}) => {
	const { formatMessage } = useIntl();
	const [{ commentsMarkedAsRead }, { setCommentsMarkedAsRead }] = useCommentsPanel();

	// for the unread indicator, we only want to render it if there are any hidden unread
	const unreadRepliesNotMarkedAsRead = useMemo(() => {
		return unreadReplyIds.filter((replyId) => {
			const markedReplies = commentsMarkedAsRead[commentType][threadKey];
			// Check if the reply id is not in the markedReplies set
			return !markedReplies || !markedReplies.has(replyId);
		});
	}, [unreadReplyIds, commentsMarkedAsRead, threadKey, commentType]);

	// considers replies marked as read due to hiding/showing replies
	const hasHiddenUnreadReplies =
		unreadRepliesNotMarkedAsRead.filter((replyId) => !latestReplyIds.has(replyId)).length > 0;

	const maxProfilePicturesToShow = 3;
	const shouldShowHideRepliesToggle = numOfHiddenReplies.current > 0;
	const shouldShowProfilePictures = !isAllRepliesView && shouldShowHideRepliesToggle;
	const shouldShowShortenedBranchStyles = !isParentOpen && shouldShowHideRepliesToggle;
	const hiddenReplies = replies.filter((reply) =>
		!shouldShowLatestReplies || !isParentOpen ? true : !latestReplyIds.has(reply.id),
	);

	// filter out duplicate authors when we're showing the profile pictures
	const getProfilePicturesOfHiddenReplies = (
		repliesToFilter: ReplyData[],
	): ProfilePictureInfo[] => {
		if (!fg('confluence_frontend_comments_panel_v2')) {
			return [];
		}

		const uniqueAccountsMap = new Map<string, ProfilePictureInfo>();
		// Start from the end of the list and move backwards
		for (let i = repliesToFilter.length - 1; i >= 0; i--) {
			const reply = repliesToFilter[i];
			const { displayName, profilePicture } = reply.author;
			// Determine if the author has an accountId, anonymous users don't have accountId
			let uniqueKey: string;
			if ('accountId' in reply.author && reply.author.accountId) {
				uniqueKey = reply.author.accountId;
			} else {
				// Fallback for anonymous users or if accountId is not present
				uniqueKey = `anonymous-${i}`;
			}
			if (!uniqueAccountsMap.has(uniqueKey)) {
				uniqueAccountsMap.set(uniqueKey, {
					displayName: displayName ?? '',
					profilePicturePath: profilePicture?.path,
				});
			}
		}
		return Array.from(uniqueAccountsMap.values());
	};

	const profilePicturesHiddenReplies = useRef<ProfilePictureInfo[]>(
		shouldShowProfilePictures ? getProfilePicturesOfHiddenReplies(hiddenReplies) : [],
	);

	const showHiddenReplies = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();
			setReplyView(ViewReplyOptions.ALL);

			if (
				commentType === CommentTypeEnum.GENERAL &&
				fg('confluence-frontend-comments-panel-design-update')
			) {
				const topLevelReplyIds = new Set(
					replies.filter((reply) => reply.parentId === parentCommentId).map((reply) => reply.id),
				);
				const visibleReplies = replies.filter((reply) => topLevelReplyIds.has(reply.id));
				setVisibleReplies(visibleReplies);
			} else {
				setVisibleReplies(replies);
			}

			profilePicturesHiddenReplies.current = [];
			numOfHiddenReplies.current = 0;
		},
		[setReplyView, commentType, numOfHiddenReplies, replies, setVisibleReplies, parentCommentId],
	);

	const hideReplies = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			event.stopPropagation();

			setReplyView(ViewReplyOptions.DEFAULT);

			// all unread replies will be marked as read
			setCommentsMarkedAsRead({
				threadKey,
				commentIds: unreadReplyIds,
				commentType,
			});

			setVisibleReplies([]);
			setShouldShowLatestReplies(false);

			// all replies will be considered
			profilePicturesHiddenReplies.current = getProfilePicturesOfHiddenReplies(replies);

			numOfHiddenReplies.current = replies.length;
		},
		[
			threadKey,
			replies,
			setReplyView,
			setVisibleReplies,
			setCommentsMarkedAsRead,
			unreadReplyIds,
			numOfHiddenReplies,
			commentType,
			setShouldShowLatestReplies,
		],
	);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			css={
				replies.length > 0 &&
				fg('confluence_frontend_comments_panel_v2') && [
					(canAddComments || numOfHiddenReplies.current === 0) && repliesTogglerBranchingStyle,
					shouldShowShortenedBranchStyles && repliesTogglerBranchingStyleWithoutReply,
				]
			}
		>
			<WithCurvedBranchingStyle
				testId="comments-panel-replies-toggler-container"
				showDefaultStyles={replies.length > 0 && fg('confluence_frontend_comments_panel_v2')}
				disableLeftProp
				customStyles={{
					...repliesTogglerStyles,
					...(fg('confluence_frontend_comments_panel_v2') ? replyWrapperWithSpacingStyles : {}),
				}}
			>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives */}
				<div css={withLeftMargin}>
					{shouldShowHideRepliesToggle ? (
						<Pressable
							onClick={showHiddenReplies}
							testId="comments-panel-thread-show-hidden-replies"
							analyticsContext={{
								attributes: {
									name: 'showHiddenReplies',
									source: AnalyticsSource.COMMENTS_PANEL,
									commentType,
									hasHiddenUnreadReplies,
									numOfHiddenReplies: numOfHiddenReplies.current,
									numOfReplies: replies.length,
								},
							}}
							xcss={pressableStyles}
							interactionName="comments-panel-thread-show-hidden-replies"
						>
							<Flex alignItems="center" gap="space.050">
								{shouldShowProfilePictures && profilePicturesHiddenReplies.current.length > 0 && (
									<AvatarGroup
										data={profilePicturesHiddenReplies.current.map(
											(profilePicturesInfo: ProfilePictureInfo) => ({
												name: profilePicturesInfo.displayName,
												src: profilePicturesInfo.profilePicturePath,
											}),
										)}
										maxCount={maxProfilePicturesToShow}
										appearance="stack"
										size="small"
										shouldPopupRenderToParent
										testId="replies-toggler-profile-pictures"
									/>
								)}
								{!fg('confluence_frontend_comments_panel_v2') && (
									<ChevronDownIcon spacing="compact" label="show-more-replies-button" />
								)}
								<Flex xcss={unreadIndicatorStyle}>
									{replies.length === 1
										? formatMessage(i18n.commentsPanelShowReplyText)
										: formatMessage(i18n.commentsPanelShowMoreRepliesText, {
												numOfHiddenReplies: numOfHiddenReplies.current,
											})}
									{hasHiddenUnreadReplies && (
										<span
											css={dotStyles}
											data-testid="comments-panel-unread-comment-replies-indicator"
										/>
									)}
								</Flex>
							</Flex>
						</Pressable>
					) : (
						<Button
							onClick={hideReplies}
							testId="comments-panel-thread-hide-replies"
							appearance="subtle"
							analyticsContext={{
								attributes: {
									name: 'hideReplies',
									source: AnalyticsSource.COMMENTS_PANEL,
									commentType,
									numOfReplies: replies.length,
								},
							}}
							interactionName="comments-panel-thread-hide-replies"
						>
							<Flex alignItems="center" gap="space.050">
								{!fg('confluence_frontend_comments_panel_v2') && (
									<ChevronUpIcon spacing="compact" label="hide-replies-button" />
								)}
								<Text color="color.text" size="medium" weight="medium">
									{replies.length === 1
										? formatMessage(i18n.commentsPanelHideReplyText)
										: formatMessage(i18n.commentsPanelHideRepliesText)}
								</Text>
							</Flex>
						</Button>
					)}
				</div>
			</WithCurvedBranchingStyle>
		</div>
	);
};
