import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const CommentsPanelQuery = gql`
	query CommentsPanelQuery($pageId: ID!) {
		comments(pageId: $pageId, type: UNRESOLVED, depth: ROOT, first: 50) {
			nodes {
				...inlineCommentFragment
				createdAt {
					value
				}
				createdAtNonLocalized
				replies {
					...inlineCommentFragment
					createdAt {
						value
					}
					createdAtNonLocalized
				}
			}
			totalCount
		}
	}
	${inlineCommentFragment}
`;
