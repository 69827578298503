import { useQuery } from 'react-apollo';

import { usePageContentId } from '@confluence/page-context';

import { ContentStatusQuery } from './queries/ContentStatusQuery.graphql';
import type {
	ContentStatusQuery as ContentStatusQueryType,
	ContentStatusQueryVariables,
} from './queries/__types__/ContentStatusQuery';

type UseContentStatusProps = {
	skip?: boolean;
};

export const useContentStatus = ({ skip = false }: UseContentStatusProps = {}) => {
	const [contentId] = usePageContentId();
	const { loading, error, data, refetch } = useQuery<
		ContentStatusQueryType,
		ContentStatusQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentStatusQuery,
		{
			skip: skip || !contentId,
			variables: {
				contentId: contentId ?? '',
			},
		},
	);

	const contentNode = data?.content?.nodes?.[0];

	return {
		contentStatus: contentNode?.status,
		loading,
		error,
		refetch,
		type: contentNode?.type,
	};
};
