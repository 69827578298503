import gql from 'graphql-tag';

export const ReopenCommentMutation = gql`
	mutation ReopenCommentMutation($commentId: ID!, $cloudId: ID!) {
		confluence_reopenComment(commentId: $commentId, cloudId: $cloudId) {
			success
			commentResolutionStates {
				commentId
				status
			}
			errors {
				message
				extensions {
					... on ReopenCommentsMutationErrorExtension {
						errorType
						statusCode
					}
					__typename
				}
			}
		}
	}
`;
