import type { ReactNode } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import Heading from '@atlaskit/heading';
import { Stack, xcss, Box } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { useCommentsPanel, ViewValues } from '@confluence/comments-panel-utils';

type EmptyStateProps = {
	SVG: () => React.JSX.Element;
	emptyCommentsHeaderText: ReactNode;
	emptyCommentsBodyText: ReactNode;
	isOpenView?: boolean;
	hasFullWidth?: boolean;
};

const emptyStyles = xcss({
	alignItems: 'center',
	gap: 'space.200',
});

const textStyle = xcss({
	width: '305px',
	textAlign: 'center',
});

const buttonStyle = css({
	marginTop: `${token('space.negative.050')}`,
});

const i18n = defineMessages({
	openViewCommentButton: {
		id: 'comments-panel.empty.unread.comments.view.open.comments',
		defaultMessage: 'View open comments',
		description: 'View open comments call to action for unread comments empty state',
	},
});

export const EmptyStateComponent = ({
	SVG,
	emptyCommentsHeaderText,
	emptyCommentsBodyText,
	isOpenView,
}: EmptyStateProps) => {
	const [, { setCurrentView }] = useCommentsPanel();

	const { formatMessage } = useIntl();

	return (
		<Stack xcss={emptyStyles} testId="empty-comments-panel-view">
			<SVG />
			<Heading size="small">{emptyCommentsHeaderText}</Heading>
			<Box xcss={textStyle}>{emptyCommentsBodyText}</Box>

			<div css={buttonStyle}>
				{!isOpenView && (
					<Button
						onClick={() => {
							setCurrentView(ViewValues.OPEN);
						}}
						testId="comments-panel-open-view-comments-button"
						analyticsContext={{
							attributes: {
								name: 'viewOpenCommentsButton',
								source: AnalyticsSource.COMMENTS_PANEL,
							},
						}}
					>
						{formatMessage(i18n.openViewCommentButton)}
					</Button>
				)}
			</div>
		</Stack>
	);
};
