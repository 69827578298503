import React from 'react';

export const EmptyUnreadSVG = () => {
	return (
		<svg
			width="127"
			height="126"
			viewBox="0 0 127 126"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M106.207 40.3291H20.793V92.3419H106.207V40.3291Z" fill="#DDDEE1" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M77.209 27.3555C66.0687 27.3555 57.322 36.0127 54 40.3301C56.2146 44.6587 66.0687 53.3048 77.209 53.3048C88.3493 53.3048 98.2033 44.6476 100.418 40.3301C97.1072 36.0015 88.3493 27.3555 77.209 27.3555ZM77.209 30.9347C82.3988 30.9347 86.6044 35.1403 86.6044 40.3301C86.6044 45.52 82.3988 49.7256 77.209 49.7256C72.0191 49.7256 67.8135 45.52 67.8135 40.3301C67.8135 35.1403 72.0191 30.9347 77.209 30.9347Z"
				fill="#1868DB"
			/>
			<path
				d="M63.5002 89.1211L51.5462 91.105C51.5462 91.105 52.6963 96.5711 49.9609 101.497C50.3275 101.529 50.694 101.552 51.0697 101.552C57.9379 101.552 63.5002 95.9847 63.5002 89.1211Z"
				fill="#DDDEE1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M95.5865 61.3508H31.4136V58.1436H95.5865V61.3508Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M95.5865 70.217H31.4136V67.0098H95.5865V70.217Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M79.3577 79.0784H31.4136V75.8711H79.3577V79.0784Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M100.417 40.3291H86.6044C86.6044 40.3294 86.6044 40.3298 86.6044 40.3301C86.6044 45.52 82.3988 49.7256 77.209 49.7256C72.0191 49.7256 67.8135 45.52 67.8135 40.3301C67.8135 40.3298 67.8135 40.3294 67.8135 40.3291H54.0008C54.0005 40.3294 54.0003 40.3298 54 40.3301C56.2146 44.6587 66.0687 53.3048 77.209 53.3048C88.3493 53.3048 98.2033 44.6476 100.418 40.3301C100.418 40.3298 100.417 40.3294 100.417 40.3291Z"
				fill="#09326C"
			/>
		</svg>
	);
};
