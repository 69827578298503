import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const ResolvedInlineCommentsQuery = gql`
	query ResolvedInlineCommentsQuery($pageId: ID!, $contentStatus: [GraphQLContentStatus!]) {
		comments(
			pageId: $pageId
			contentStatus: $contentStatus
			confluenceCommentFilter: { commentState: RESOLVED, commentType: INLINE }
		) @allowHigherTimeout {
			nodes {
				...inlineCommentFragment
				location {
					type
					... on InlineComment {
						inlineMarkerRef
						inlineText
						inlineResolveProperties {
							resolvedByDangling
							resolvedTime
							resolvedFriendlyDate
							resolvedUser
							resolved
						}
					}
				}
				createdAt {
					value
				}
				createdAtNonLocalized
				replies {
					...inlineCommentFragment
					createdAt {
						value
					}
					createdAtNonLocalized
				}
			}
			totalCount
		}
	}
	${inlineCommentFragment}
`;
