import gql from 'graphql-tag';

import { commentFragment } from './CommentFragment.fragment';

// eslint-disable-next-line graphql-relay-compat/consistent-graphql-operation-naming, graphql-relay-compat/no-apollo-fragments -- Read https://go/connie-relay-migration-fyi
export const ResolvedCommentsQuery = gql`
	query ResolvedCommentsQuery($pageId: ID!, $contentStatus: [GraphQLContentStatus]) {
		comments(
			pageId: $pageId
			contentStatus: $contentStatus
			depth: ROOT
			confluenceCommentFilter: { commentState: RESOLVED }
			singleThreaded: true
		) {
			nodes {
				...commentFragment
				replies {
					...commentFragment
				}
			}
		}
	}
	${commentFragment}
`;
