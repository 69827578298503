import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { xcss, Flex, Pressable } from '@atlaskit/primitives';
import AvatarGroup from '@atlaskit/avatar-group';

import { WithCurvedBranchingStyle } from '@confluence/inline-comments-common/entry-points/components';
import type { ReplyData } from '@confluence/comments-data';
import { scrollToElement } from '@confluence/scroll';
// This component is used to scroll down to the footer for deeply nested replies
const replyScrollerStyles = {
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	marginTop: token('space.150'),
	left: 0,
};

const replyScrollerBranchingStyle = css({
	position: 'relative',
	marginLeft: token('space.200'),
});

const replyWrapperWithSpacingStyles = {
	paddingTop: token('space.100'),
};

const leftMargin = css({
	marginLeft: token('space.150'),
});

const unreadIndicatorStyle = xcss({
	display: 'flex',
	alignItems: 'center',
});

// This snippet is being used in CommentBody.tsx
const dotStyles = css({
	height: token('space.100'),
	width: token('space.100'),
	backgroundColor: token('color.background.brand.bold'),
	borderRadius: '50%',
	margin: token('space.050'),
});

const pressableStyles = xcss({
	color: 'color.text.subtle',
	backgroundColor: 'color.background.neutral.subtle',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	borderRadius: 'border.radius.100',
	userSelect: 'none',
	fontWeight: token('font.weight.medium'),

	':hover': {
		cursor: 'pointer',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const i18n = defineMessages({
	commentsPanelShowGeneralNestedReplies: {
		id: 'comments-panel.comment-thread.go.to.nested.general.reply',
		defaultMessage: 'Go to more replies',
		description: 'Text for scrolling to nested footer / general replies',
	},
	commentsPanelGeneralNestedRepliesHasUnread: {
		id: 'comments-panel.comment-thread.general.nested.replies.has.unread',
		defaultMessage: 'There are unread replies',
		description: 'Text for the unread replies indicator label',
	},
});

type ProfilePictureInfo = {
	displayName: string;
	profilePicturePath: string | undefined;
};

export const GeneralReplyScroller = ({
	nestedReplies,
	unreadReplyIds,
	targetCommentIdForScroll,
}: {
	nestedReplies: ReplyData[];
	unreadReplyIds: string[];
	targetCommentIdForScroll: string;
}) => {
	const { formatMessage } = useIntl();

	const maxProfilePicturesToShow = 3;

	// If any of the nestedReplies match an ID in unreadReplyIds, render the blue dot indicator
	const unreadReplyIdsSet = new Set(unreadReplyIds);
	const shouldRenderBlueDotIndicator = nestedReplies.some((reply) =>
		unreadReplyIdsSet.has(reply.id),
	);

	const avatarsToShow = useMemo(() => {
		const uniqueAccountsMap = new Map<string, ProfilePictureInfo>();
		// Start from the end of the list and move backwards
		for (let i = nestedReplies.length - 1; i >= 0; i--) {
			const reply = nestedReplies[i];
			const { displayName, profilePicture } = reply.author;
			// Determine if the author has an accountId, anonymous users don't have accountId
			let uniqueKey: string;
			if ('accountId' in reply.author && reply.author.accountId) {
				uniqueKey = reply.author.accountId;
			} else {
				// Fallback for anonymous users or if accountId is not present
				uniqueKey = `anonymous-${i}`;
			}
			if (!uniqueAccountsMap.has(uniqueKey)) {
				uniqueAccountsMap.set(uniqueKey, {
					displayName: displayName ?? '',
					profilePicturePath: profilePicture?.path,
				});
			}
		}
		const avatars = Array.from(uniqueAccountsMap.values());

		const mappedAvatars = avatars.map((profilePicturesInfo: ProfilePictureInfo) => ({
			name: profilePicturesInfo.displayName,
			src: profilePicturesInfo.profilePicturePath,
		}));
		return mappedAvatars;
	}, [nestedReplies]);

	// NOTE: This component replicates a lot of the code in RepliesToggler.tsx
	// However, GeneralReplyScroller is only used for multi nested general comment replies for pages created before the single threading general comments feature was introduced
	// All new pages created after this time will use RepliesScroller instead of GeneralReplyScroller
	return (
		<div css={replyScrollerBranchingStyle}>
			<WithCurvedBranchingStyle
				testId="comments-panel-reply-scroller-container"
				showDefaultStyles
				customStyles={{
					...replyScrollerStyles,
					...replyWrapperWithSpacingStyles,
				}}
				disableLeftProp
			>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives */}
				<div css={leftMargin}>
					<Pressable
						onClick={() => {
							const commentElement = document.getElementById(`comment-${targetCommentIdForScroll}`);
							if (commentElement) {
								scrollToElement(commentElement, -75, 'smooth');
							}
						}}
						testId="comments-panel-thread-show-nested-general-replies"
						xcss={pressableStyles}
						interactionName="comments-panel-thread-show-nested-general-replies"
					>
						<Flex alignItems="center" gap="space.050">
							{avatarsToShow.length > 0 && (
								<AvatarGroup
									data={avatarsToShow}
									maxCount={maxProfilePicturesToShow}
									appearance="stack"
									size="small"
									shouldPopupRenderToParent
									testId="comments-panel-replies-scroller-profile-pictures"
								/>
							)}
							<Flex alignItems="center" gap="space.050">
								<Flex xcss={unreadIndicatorStyle}>
									{formatMessage(i18n.commentsPanelShowGeneralNestedReplies)}
									{shouldRenderBlueDotIndicator && (
										<span
											css={dotStyles}
											data-testid="comments-panel-unread-nested-general-replies-indicator"
											aria-label={formatMessage(i18n.commentsPanelGeneralNestedRepliesHasUnread)}
										/>
									)}
								</Flex>
							</Flex>
						</Flex>
					</Pressable>
				</div>
			</WithCurvedBranchingStyle>
		</div>
	);
};
