import gql from 'graphql-tag';

import { commentFragment } from './GeneralCommentFragment.fragment';

export const CreateGeneralReplyMutation = gql`
	mutation CreateGeneralReplyMutation($pageId: ID!, $cloudId: ID!, $input: CreateCommentInput!) {
		createFooterComment(cloudId: $cloudId, input: $input) {
			...commentFragment
		}
	}

	${commentFragment}
`;
