/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContentRepresentation {
  ATLAS_DOC_FORMAT = "ATLAS_DOC_FORMAT",
  EDITOR = "EDITOR",
  EDITOR2 = "EDITOR2",
  EXPORT_VIEW = "EXPORT_VIEW",
  PLAIN = "PLAIN",
  RAW = "RAW",
  STORAGE = "STORAGE",
  STYLED_VIEW = "STYLED_VIEW",
  VIEW = "VIEW",
  WIKI = "WIKI",
}

export enum Platform {
  ANDROID = "ANDROID",
  IOS = "IOS",
  WEB = "WEB",
}

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

export interface CommentBody {
  representationFormat: ContentRepresentation;
  value: string;
}

export interface CreateCommentInput {
  commentBody: CommentBody;
  commentSource?: Platform | null;
  containerId: string;
  parentCommentId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGeneralCommentMutation
// ====================================================

export interface CreateGeneralCommentMutation_createFooterComment_author_Anonymous_profilePicture {
  path: string;
}

export interface CreateGeneralCommentMutation_createFooterComment_author_Anonymous {
  displayName: string | null;
  profilePicture: CreateGeneralCommentMutation_createFooterComment_author_Anonymous_profilePicture | null;
  permissionType: SitePermissionType | null;
}

export interface CreateGeneralCommentMutation_createFooterComment_author_KnownUser_profilePicture {
  path: string;
}

export interface CreateGeneralCommentMutation_createFooterComment_author_KnownUser {
  displayName: string | null;
  profilePicture: CreateGeneralCommentMutation_createFooterComment_author_KnownUser_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export interface CreateGeneralCommentMutation_createFooterComment_author_User_profilePicture {
  path: string;
}

export interface CreateGeneralCommentMutation_createFooterComment_author_User {
  displayName: string | null;
  profilePicture: CreateGeneralCommentMutation_createFooterComment_author_User_profilePicture | null;
  permissionType: SitePermissionType | null;
  accountId: string | null;
}

export type CreateGeneralCommentMutation_createFooterComment_author = CreateGeneralCommentMutation_createFooterComment_author_Anonymous | CreateGeneralCommentMutation_createFooterComment_author_KnownUser | CreateGeneralCommentMutation_createFooterComment_author_User;

export interface CreateGeneralCommentMutation_createFooterComment_body {
  value: string;
}

export interface CreateGeneralCommentMutation_createFooterComment_createdAt {
  value: string;
}

export interface CreateGeneralCommentMutation_createFooterComment_links {
  webui: string | null;
  editui: string | null;
}

export interface CreateGeneralCommentMutation_createFooterComment_location_InlineComment_inlineResolveProperties {
  resolvedByDangling: boolean;
  resolvedTime: any | null;
  resolvedFriendlyDate: string | null;
  resolvedUser: string | null;
  resolved: boolean;
}

export interface CreateGeneralCommentMutation_createFooterComment_location_InlineComment {
  type: string;
  inlineMarkerRef: string | null;
  inlineText: string | null;
  inlineResolveProperties: CreateGeneralCommentMutation_createFooterComment_location_InlineComment_inlineResolveProperties | null;
}

export interface CreateGeneralCommentMutation_createFooterComment_location_FooterComment_commentResolveProperties {
  resolvedTime: any | null;
  resolvedFriendlyDate: string | null;
  resolvedUser: string | null;
  resolved: boolean;
}

export interface CreateGeneralCommentMutation_createFooterComment_location_FooterComment {
  type: string;
  commentResolveProperties: CreateGeneralCommentMutation_createFooterComment_location_FooterComment_commentResolveProperties | null;
}

export type CreateGeneralCommentMutation_createFooterComment_location = CreateGeneralCommentMutation_createFooterComment_location_InlineComment | CreateGeneralCommentMutation_createFooterComment_location_FooterComment;

export interface CreateGeneralCommentMutation_createFooterComment_permissions {
  isEditable: boolean;
  isRemovable: boolean;
  isResolvable: boolean;
}

export interface CreateGeneralCommentMutation_createFooterComment_reactionsSummary_reactionsSummaryForEmoji {
  id: string;
  emojiId: string;
  count: number;
  reacted: boolean;
}

export interface CreateGeneralCommentMutation_createFooterComment_reactionsSummary {
  ari: string;
  containerAri: string;
  reactionsCount: number;
  reactionsSummaryForEmoji: (CreateGeneralCommentMutation_createFooterComment_reactionsSummary_reactionsSummaryForEmoji | null)[];
}

export interface CreateGeneralCommentMutation_createFooterComment_version {
  when: string | null;
  number: number | null;
}

export interface CreateGeneralCommentMutation_createFooterComment {
  id: string;
  author: CreateGeneralCommentMutation_createFooterComment_author;
  body: CreateGeneralCommentMutation_createFooterComment_body;
  contentStatus: string;
  createdAt: CreateGeneralCommentMutation_createFooterComment_createdAt;
  createdAtNonLocalized: string;
  links: CreateGeneralCommentMutation_createFooterComment_links;
  location: CreateGeneralCommentMutation_createFooterComment_location;
  parentId: string | null;
  permissions: CreateGeneralCommentMutation_createFooterComment_permissions;
  reactionsSummary: CreateGeneralCommentMutation_createFooterComment_reactionsSummary | null;
  version: CreateGeneralCommentMutation_createFooterComment_version;
}

export interface CreateGeneralCommentMutation {
  createFooterComment: CreateGeneralCommentMutation_createFooterComment;
}

export interface CreateGeneralCommentMutationVariables {
  pageId: string;
  cloudId: string;
  input: CreateCommentInput;
}
