import React, { useEffect } from 'react';
import { css } from '@compiled/react';

import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { CommentData } from '@confluence/comments-data';
import type { FlagsStateContainer } from '@confluence/flags';
import { CommentBatchList } from '@confluence/comment/entry-points/CommentBatchList';
import { useCommentsPanelActions } from '@confluence/comments-panel-utils';

import { getCommentThreadsToBatch } from '../helper/commentThreadHelper';

import { CommentThread } from './CommentThread';

type CommentPanelListProps = {
	commentThreads: CommentData[];
	supportedTopLevelActions: CommentAction[];
	flags?: FlagsStateContainer;
};

const listStyles = css({
	overflowY: 'auto',
	overflowX: 'hidden',
	flexGrow: 1,
	display: 'block',
});

// this component holds a list of comments
export const CommentsPanelList = ({
	commentThreads,
	supportedTopLevelActions,
	flags,
}: CommentPanelListProps) => {
	const pageMode = useGetPageMode();
	const { setCurrentlyRenderedThreads } = useCommentsPanelActions();

	const eventEmitter =
		pageMode === PageMode.EDIT || pageMode === PageMode.LIVE
			? getEditorAnnotationEventEmitter()
			: getRendererAnnotationEventEmitter();

	// Whenever the list that we're rendering here changes, we want to update the list that the keyboard shortcuts are listening to
	useEffect(() => {
		setCurrentlyRenderedThreads(commentThreads.map((thread) => thread.location));

		return () => {
			// When this component is unmounted, we want to clear the list that the keyboard shortcuts are listening to
			setCurrentlyRenderedThreads([]);
		};
	}, [commentThreads, setCurrentlyRenderedThreads]);

	const actionsToShowUsers =
		pageMode === PageMode.EDIT
			? supportedTopLevelActions.filter((action) => action !== 'delete')
			: supportedTopLevelActions;

	// Props we want the batch renderer to pass through that aren't comment specific
	const passThroughProps = {
		supportedTopLevelActions: actionsToShowUsers,
		eventEmitter,
		pageMode,
		flags,
	};

	return (
		<div
			id="comments-panel-list-container"
			data-testid="comments-panel-list-container"
			css={listStyles}
		>
			<CommentBatchList
				commentsList={commentThreads}
				passThroughProps={passThroughProps}
				Component={CommentThread}
				getElementsToBatch={getCommentThreadsToBatch}
			/>
		</div>
	);
};
