import { useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { MarkCommentsReadMutation } from '@confluence/inline-comments-queries';
import { useCommentsData, UnreadAction } from '@confluence/comments-data';
import { useUnreadComments } from '@confluence/unread-comments';
import { useCommentsPanel } from '@confluence/comments-panel-utils';

export const useUnreadPanelComments = () => {
	const [{ commentsMarkedAsRead }, { clearCommentsMarkedAsRead }] = useCommentsPanel();

	const [, { updateUnreadStatus }] = useCommentsData();

	const [
		{ readCommentsListState, unreadCommentsListState },
		{ updateReadCommentsListState, updateUnreadCommentsListState },
	] = useUnreadComments();

	// TODO: handle error
	const [markInlineCommentsRead] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		MarkCommentsReadMutation,
	);

	// call the mutation to mark the list of comment IDs as read
	const handleMarkCommentsAsRead = useCallback(() => {
		if (
			Object.keys(commentsMarkedAsRead.inline).length > 0 ||
			Object.keys(commentsMarkedAsRead.general).length > 0
		) {
			const inlineCommentIdsToMarkAsRead = Object.values(commentsMarkedAsRead.inline).reduce<
				string[]
			>((acc, set) => {
				acc.push(...set);
				return acc;
			}, []);

			const generalCommentIdsToMarkAsRead = Object.values(commentsMarkedAsRead.general).reduce<
				string[]
			>((acc, set) => {
				acc.push(...set);
				return acc;
			}, []);

			const commentIdsToMarkAsRead = [
				...inlineCommentIdsToMarkAsRead,
				...generalCommentIdsToMarkAsRead,
			];

			void markInlineCommentsRead({
				variables: { commentIds: commentIdsToMarkAsRead },
			});

			// update our data store to indicate these comments are now read
			updateUnreadStatus(commentsMarkedAsRead, UnreadAction.READ);

			// reset our list of comments to mark as read
			clearCommentsMarkedAsRead();

			// update existing unread and read data stores
			const commentIdsToMarkAsReadSet = new Set(commentIdsToMarkAsRead);
			const filteredUnreadComments = unreadCommentsListState.filter(
				(unreadComment) => !commentIdsToMarkAsReadSet.has(unreadComment.id),
			);
			updateUnreadCommentsListState(() => filteredUnreadComments);

			const newReadCommentsListState = new Set([
				...readCommentsListState,
				...commentIdsToMarkAsRead,
			]);
			updateReadCommentsListState(() => newReadCommentsListState);
		}
	}, [
		markInlineCommentsRead,
		clearCommentsMarkedAsRead,
		commentsMarkedAsRead,
		updateUnreadStatus,
		readCommentsListState,
		unreadCommentsListState,
		updateReadCommentsListState,
		updateUnreadCommentsListState,
	]);

	useEffect(() => {
		const handleBeforeUnload = () => {
			// Mark comments as read if the user closes the window
			handleMarkCommentsAsRead();
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			// Mark comments as read when the component unmounts
			handleMarkCommentsAsRead();

			window.removeEventListener('beforeunload', handleBeforeUnload);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		handleMarkCommentsAsRead,
	};
};
