import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { css } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AnnotationInfo } from '@atlaskit/editor-plugin-annotation';
import Button from '@atlaskit/button/new';

import { CommentEditor } from '@confluence/comment';
import { NewReplyContainer } from '@confluence/inline-comments-common/entry-points/styled';
import type {
	InlineCommentReply,
	NewReplyVars,
	CommentLocation,
	CommentReply,
} from '@confluence/inline-comments-queries';
import { CreateInlineReplyMutation } from '@confluence/inline-comments-queries';
import { CreateGeneralReplyMutation } from '@confluence/page-comments-queries/entry-points/CreateGeneralReplyMutation.graphql';
import type {
	CreateGeneralReplyMutationData,
	CreateGeneralReplyMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralReplyMutation';
import {
	useCommentsData,
	CommentType,
	type CommentData,
	type ReplyData,
} from '@confluence/comments-data';
import {
	ReactionsContext,
	useCommentsContentActions,
	useCommentsContentState,
} from '@confluence/comment-context';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import {
	ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
	REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useUnreadComments } from '@confluence/unread-comments';
import type { PageInfoNode } from '@confluence/page-info';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { fg } from '@confluence/feature-gating';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { WithCurvedBranchingStyle } from '@confluence/inline-comments-common/entry-points/components';
import { useSessionData } from '@confluence/session-data';
import { useSearchSessionId } from '@confluence/search-session';
import { useCommentsPanel } from '@confluence/comments-panel-utils';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { getValidDate, onSaveReply, ViewReplyOptions } from '../helper/commentThreadHelper';

import { Comment } from './Comment';
import { RepliesToggler } from './RepliesToggler';
import { GeneralReplyScroller } from './GeneralReplyScroller';

export const defaultNumVisibleReplies = 2;

const commentThreadRepliesBoxStyles = css({
	display: 'flex',
	flexDirection: 'column',
	paddingLeft: token('space.200'),
	paddingRight: token('space.100'),
});

const replyBoxStyles = css({
	listStyle: 'none',
	position: 'relative',
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.300'),
});

const oldReplyBoxStyles = css({
	listStyle: 'none',
	position: 'relative',
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.200'),
});

const translucentStyle = css({
	opacity: '50%',
});

const replyListStaticStraightBorders = css({
	paddingInlineStart: token('space.0'),
	borderLeft: `2px solid ${token('color.border')}`,
});

const replyActionButtonStyles = {
	marginLeft: token('space.150'),
	width: '62px',
	height: '24px',
	left: '-12px',
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
};

const customizedReplyButtonStyles = css({
	'&::after': {
		borderStyle: 'none',
	},
});

const commentEditorCustomStyles = {
	marginLeft: token('space.300'),
	height: '16px',
	marginRight: token('space.050'),
};

export type CommentRepliesProps = {
	parentComment: CommentData;
	pageInfo: PageInfoNode | null;
	isCurrentSelectedComment: boolean;
	hoveredCommentId: string;
	pageMode: PageMode;
	editCommentQueryId: string;
	selectedAnnotation: AnnotationInfo;
	parentCommentId: string;
	handleMouseEnter: (id?: string) => void;
	handleMouseLeave: () => void;
	canAddComments: boolean;
};

const i18n = defineMessages({
	replyAction: {
		id: 'comments-panel.reply.action',
		defaultMessage: 'Reply',
		description: 'Reply button label for comment thread in comments panel',
	},
});

export const CommentReplies = ({
	parentComment,
	pageInfo,
	isCurrentSelectedComment,
	hoveredCommentId,
	pageMode,
	editCommentQueryId,
	selectedAnnotation,
	parentCommentId,
	handleMouseEnter,
	handleMouseLeave,
	canAddComments,
}: CommentRepliesProps) => {
	const [createInlineReplyFn] = useMutation<
		{ replyInlineComment: InlineCommentReply },
		{ input: NewReplyVars; pageId: string }
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateInlineReplyMutation,
	);

	const [createGeneralReplyFn] = useMutation<
		CreateGeneralReplyMutationData,
		CreateGeneralReplyMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateGeneralReplyMutation,
	);

	const { isReactionsEnabled } = useContext(ReactionsContext);

	const { hasContentChanged } = useCommentsContentState();
	const { onChange, resetContentChanged } = useCommentsContentActions();

	const { cloudId } = useSessionData();
	const [{ searchSessionId }] = useSearchSessionId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { addReplyToCommentThread }] = useCommentsData();
	const [, { updateReadCommentsListState }] = useUnreadComments();
	const [
		{ commentsMarkedAsRead, replyToCommentId },
		{ setCommentsMarkedAsRead, setReplyToCommentId },
	] = useCommentsPanel();

	const allReplies = useMemo(() => {
		return parentComment.replies.filter((r) => (r as CommentReply) !== null);
	}, [parentComment.replies]);

	const unreadReplyIds = useMemo(() => {
		return allReplies.filter((r) => r.isUnread).map((r) => r.id);
	}, [allReplies]);

	// Show the latest replies in the thread by default
	const [shouldShowLatestReplies, setShouldShowLatestReplies] = useState(true);
	const [visibleReplies, setVisibleReplies] = useState<ReplyData[]>([]);
	const [, setCommentForEdit] = useState('');
	const [currentReplyView, setReplyView] = useState(ViewReplyOptions.DEFAULT);

	const { showModal } = useDialogs();

	const experienceTracker = useContext(ExperienceTrackerContext);

	const pageId = pageInfo?.id ?? '';
	const pageType = pageInfo?.type ?? '';
	const commentType = parentComment.type;
	const isParentCommentOpen = parentComment.isOpen;

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	const threadKey =
		commentType === CommentType.INLINE
			? (parentComment?.location as CommentLocation).inlineMarkerRef || ''
			: parentCommentId;

	const supportedTopLevelActions: CommentAction[] = isParentCommentOpen
		? ['edit', 'resolve', 'delete']
		: ['reopen']; // reopen won't be rendered for reply, but we are using it to render disabled reaction summary for replies

	const expandReplyEditor = parentCommentId === replyToCommentId;

	const visibleUnreadRepliesNotMarkedAsRead = useMemo(() => {
		return visibleReplies
			.filter((reply) => {
				const markedReplies = commentsMarkedAsRead[commentType][threadKey];
				// Check if the reply id is not in the markedReplies set
				return !markedReplies || !markedReplies.has(reply.id);
			})
			.map((reply) => reply.id);
	}, [visibleReplies, commentsMarkedAsRead, threadKey, commentType]);

	const numOfHiddenReplies = useRef(
		currentReplyView === ViewReplyOptions.ALL ? 0 : allReplies.length - visibleReplies.length,
	);

	const topLevelReplyToNestedChildrenMap = useMemo(() => {
		const topLevelReplyIds = new Set(
			allReplies.filter((reply) => reply.parentId === parentCommentId).map((reply) => reply.id),
		);

		// We need to get all replies that are nested to any depth under a top level reply.
		// This will help us render the GeneralReplyScroller component for each reply (mainly, we need to show user icons and the blue dot indicator)
		const topLevelReplyToNestedChildrenMapInternal: { [key: string]: ReplyData[] } = Array.from(
			topLevelReplyIds,
		).reduce<{ [key: string]: ReplyData[] }>((acc, replyId) => {
			acc[replyId] = [];
			return acc;
		}, {});

		const replyIdToReplyMap = allReplies.reduce<{ [key: string]: ReplyData }>((acc, reply) => {
			acc[reply.id] = reply;
			return acc;
		}, {});

		// Map all replies to their top level parent. Replies can be nested to any depth, so we need to traverse the tree.
		const filteredReplies = allReplies.filter((reply) => !topLevelReplyIds.has(reply.id));

		for (const filteredReply of filteredReplies) {
			let replyParentId = filteredReply.parentId;

			// Add a circuit breaker to prevent infinite loops if we get really wonky data
			let circuitBreaker = 0;
			while (replyParentId && circuitBreaker < 100 && !topLevelReplyIds.has(replyParentId)) {
				replyParentId = replyIdToReplyMap[replyParentId]?.parentId;
				circuitBreaker++;
			}

			if (replyParentId && replyParentId in topLevelReplyToNestedChildrenMapInternal) {
				// Second condition will always be true, unless we somehow hit circuit breaker above
				topLevelReplyToNestedChildrenMapInternal[replyParentId].push(filteredReply);
			}
		}

		return topLevelReplyToNestedChildrenMapInternal;
	}, [allReplies, parentCommentId]);

	// Determine the latest top level replies to show on the thread's initial load
	const latestReplies = useMemo(() => {
		const replies: ReplyData[] = [];
		const topLevelReplies = allReplies.filter(
			(reply) => reply.id in topLevelReplyToNestedChildrenMap,
		);

		// Sort by createdAtNonLocalized value
		topLevelReplies.sort((a, b) => {
			// Safely handle missing or invalid dates
			const dateA = getValidDate(a?.createdAtNonLocalized);
			const dateB = getValidDate(b?.createdAtNonLocalized);

			return dateA.getTime() - dateB.getTime();
		});

		// Add the latest replies to the replies array
		for (let i = 0; i < defaultNumVisibleReplies; i++) {
			if (topLevelReplies.length > 0) {
				const reply = topLevelReplies.pop();
				if (reply) {
					// Unshift to maintain reply chronological order
					replies.unshift(reply);
				}
			}
		}

		return replies;
	}, [allReplies, topLevelReplyToNestedChildrenMap]);

	/*
	 * Determine the latest top level reply IDs and their nested reply IDs.
	 * This is passed to RepliesToggler to determine the hidden replies.
	 */
	const latestReplyIds = useMemo(() => {
		const latestAndNestedReplyIds: Set<string> = new Set();

		for (const reply of latestReplies) {
			latestAndNestedReplyIds.add(reply.id);

			for (const nestedReply of topLevelReplyToNestedChildrenMap[reply.id]) {
				latestAndNestedReplyIds.add(nestedReply.id);
			}
		}

		return latestAndNestedReplyIds;
	}, [latestReplies, topLevelReplyToNestedChildrenMap]);

	// if selecting the comment thread, we need to mark all visible replies as read as well
	// we'll let setCommentsMarkedAsRead handle duplicates if any
	useEffect(() => {
		if (isCurrentSelectedComment) {
			setCommentsMarkedAsRead({
				threadKey,
				commentIds: visibleUnreadRepliesNotMarkedAsRead,
				commentType,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- there might be a better way to handle this without needing a useEFfect
	}, [isCurrentSelectedComment]);

	// handle updates to replies
	useEffect(() => {
		if (currentReplyView === ViewReplyOptions.ALL) {
			if (commentType !== CommentType.GENERAL) {
				setVisibleReplies(allReplies);
			} else {
				const topLevelReplies = allReplies.filter((reply) => reply.parentId === parentCommentId);
				setVisibleReplies(topLevelReplies);
			}
			numOfHiddenReplies.current = 0;
		} else {
			numOfHiddenReplies.current = allReplies.length;

			if (!isParentCommentOpen) {
				// If the parent comment is resolved, we hide all replies on the thread's initial load
				setVisibleReplies([]);
			} else if (
				shouldShowLatestReplies &&
				latestReplies &&
				latestReplyIds &&
				latestReplyIds.size > 0
			) {
				/*
				 * If the parent comment is open, we show the latest replies on the thread's initial load only.
				 * When replies are expaneded and collapsed, all replies are hidden.
				 */
				setVisibleReplies(latestReplies);
				numOfHiddenReplies.current -= latestReplyIds.size;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- there might be a better way to handle this without needing a useEFfect
	}, [allReplies, latestReplies, latestReplyIds]);

	const handleReplyButtonClick = () => {
		if (hasContentChanged) {
			// if the content has changed and the user has clicked reply button to another comment thread, warn the user
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					setReplyToCommentId(parentCommentId);
				},
			});
		} else {
			setReplyToCommentId(parentCommentId);
		}
	};

	const renderReplyButton = () => {
		return (
			isParentCommentOpen &&
			canAddComments &&
			fg('confluence_frontend_comments_panel_v2') && (
				<WithCurvedBranchingStyle
					showDefaultStyles
					customStyles={replyActionButtonStyles}
					testId="comments-panel-reply-comment-action"
					onClick={handleReplyButtonClick}
				>
					<Button
						data-cy="comments-panel-reply-comment-action"
						css={customizedReplyButtonStyles}
						appearance="subtle"
						isDisabled={!isParentCommentOpen}
						analyticsContext={{
							attributes: {
								name: 'replyToCommentButton',
								source: AnalyticsSource.COMMENTS_PANEL,
							},
						}}
						interactionName="comments-panel-reply-comment-action"
					>
						<FormattedMessage {...i18n.replyAction} />
					</Button>
				</WithCurvedBranchingStyle>
			)
		);
	};

	const renderCommentEditor = () => {
		return expandReplyEditor ? (
			<NewReplyContainer
				mode="view"
				isCommentsPanel
				data-testid="comments-panel-new-reply-container"
			>
				<WithCurvedBranchingStyle
					showDefaultStyles={fg('confluence_frontend_comments_panel_v2')}
					customStyles={
						fg('confluence_frontend_comments_panel_v2') ? commentEditorCustomStyles : {}
					}
				>
					<CommentEditor
						pageId={pageId}
						pageType={pageType}
						appearance="chromeless"
						EditorComponent={ChromelessEditor}
						onSaveComment={(adf, onSuccess) => {
							setReplyView(ViewReplyOptions.ALL);
							setReplyToCommentId();
							resetContentChanged();

							return onSaveReply({
								pageId,
								parentCommentId,
								cloudId,
								adf,
								onSuccess,
								pageMode,
								editCommentQueryId,
								setCommentForEdit,
								createAnalyticsEvent,
								pageType,
								experienceTracker,
								createInlineReplyFn,
								createGeneralReplyFn,
								selectedAnnotation,
								addReplyToCommentThread,
								threadKey,
								updateReadCommentsListState,
								commentType,
								searchSessionId,
								isReactionsEnabled,
							});
						}}
						onContentChange={onChange}
						onEditorReady={() => {
							experienceTracker.succeed({
								name:
									commentType === CommentType.INLINE
										? REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE
										: ADD_PAGE_COMMENT_LOAD_EXPERIENCE,
							});
						}}
						commentMode="reply"
						commentType="inline"
						spaceId=""
						showCancelButton
						useNewWarningModal
						hideWatchCheckbox
						expandEditor={expandReplyEditor}
						pageMode="view"
						hasMediaUploadPermissions={hasMediaUploadPermissions}
						shouldDisplayCommentReplyPrompts={false}
						topLevelCommentId={parentComment.id}
						shouldWarnOnInternalNavigation
						commentThreadLength={allReplies.length + 1}
						onCancelComment={() => {
							setReplyToCommentId();
						}}
						isCommentsPanel
						wasRemovedByAnotherUser={parentComment.wasRemovedByAnotherUser}
					/>
				</WithCurvedBranchingStyle>
			</NewReplyContainer>
		) : (
			<></>
		);
	};

	return (
		<>
			<div
				data-testid={`comment-thread-${parentComment.id}-replies`}
				css={[
					commentThreadRepliesBoxStyles,
					parentComment.wasRemovedByAnotherUser && translucentStyle,
				]}
			>
				<div>
					{allReplies.length > 0 && (
						<RepliesToggler
							replies={allReplies}
							setVisibleReplies={setVisibleReplies}
							unreadReplyIds={unreadReplyIds}
							setReplyView={setReplyView}
							threadKey={threadKey}
							isAllRepliesView={currentReplyView === ViewReplyOptions.ALL}
							numOfHiddenReplies={numOfHiddenReplies}
							commentType={commentType}
							parentCommentId={parentCommentId}
							isParentOpen={isParentCommentOpen}
							canAddComments={canAddComments}
							shouldShowLatestReplies={shouldShowLatestReplies}
							setShouldShowLatestReplies={setShouldShowLatestReplies}
							latestReplyIds={latestReplyIds}
						/>
					)}
					{visibleReplies.length > 0 && (
						<div
							css={[!fg('confluence_frontend_comments_panel_v2') && replyListStaticStraightBorders]}
							data-testid={`comment-thread-${parentComment.id}-reply-list`}
						>
							{visibleReplies.map(
								(reply, idx) =>
									reply && (
										// eslint-disable-next-line jsx-a11y/no-static-element-interactions
										<div
											css={[
												fg('confluence_frontend_comments_panel_v2')
													? replyBoxStyles
													: oldReplyBoxStyles,
											]}
											key={reply.id}
											data-testid={`comment-thread-${parentComment.id}-replies-${reply.id}`}
											onMouseEnter={() => handleMouseEnter(reply.id)}
											onMouseLeave={handleMouseLeave}
										>
											<Comment
												comment={reply}
												supportedTopLevelActions={supportedTopLevelActions}
												pageInfo={pageInfo}
												pageMode={pageMode}
												isUnread={
													!commentsMarkedAsRead[commentType][threadKey]?.has(reply.id) &&
													reply.isUnread
												}
												isHovered={hoveredCommentId === reply.id}
												threadKey={threadKey}
												// When the parent comment is resolved, hide the styles for the last reply
												hideBranchingStyle={!canAddComments && idx === visibleReplies.length - 1}
												adjustForNestedReplies={
													commentType === CommentType.GENERAL &&
													reply.id in topLevelReplyToNestedChildrenMap &&
													topLevelReplyToNestedChildrenMap[reply.id]?.length > 0 &&
													fg('confluence-frontend-comments-panel-design-update')
												}
												canAddComments={canAddComments}
												isParentCommentOpen={isParentCommentOpen}
											/>
											{commentType === CommentType.GENERAL &&
												reply.id in topLevelReplyToNestedChildrenMap &&
												topLevelReplyToNestedChildrenMap[reply.id]?.length > 0 &&
												fg('confluence-frontend-comments-panel-design-update') && (
													<GeneralReplyScroller
														nestedReplies={topLevelReplyToNestedChildrenMap[reply.id]}
														unreadReplyIds={unreadReplyIds}
														targetCommentIdForScroll={reply.id}
													/>
												)}
										</div>
									),
							)}
						</div>
					)}
				</div>
				{expandReplyEditor && fg('confluence_frontend_comments_panel_v2')
					? renderCommentEditor()
					: renderReplyButton()}
			</div>
			{expandReplyEditor && !fg('confluence_frontend_comments_panel_v2') && renderCommentEditor()}
		</>
	);
};
