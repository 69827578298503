import React from 'react';

export const EmptyResolvedSVG = () => {
	return (
		<svg
			width="127"
			height="126"
			viewBox="0 0 127 126"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M101.3 71.7281C101.831 68.7499 102.106 65.593 102.106 62.2391C102.106 58.8852 101.822 55.6413 101.286 52.6265C98.0694 34.6932 85.6068 23.6419 68.1363 20.7966C65.1536 20.3155 62.0288 20.0635 58.7803 20.0635C55.5317 20.0635 52.132 20.3292 49.0347 20.8515C31.5551 23.7702 19.12 34.7986 15.8899 52.6265C15.3446 55.6367 15.0605 58.844 15.0605 62.2391C15.0605 65.6342 15.34 68.7499 15.8715 71.7281C19.0742 89.5376 31.523 100.584 49.0301 103.503C52.1275 104.021 55.3851 104.291 58.7757 104.291C62.1662 104.291 65.1536 104.044 68.1317 103.558C85.6297 100.717 98.0968 89.6384 101.295 71.7281H101.3Z"
				fill="#DDDEE1"
			/>
			<path
				d="M73.7172 61.9231L107.219 27.0234L119.609 38.4963L70.9452 80.2687L48.0361 59.1603L59.9489 45.4057L73.7126 61.9277L73.7172 61.9231Z"
				fill="#1868DB"
			/>
			<path
				d="M101.29 52.627C100.337 47.3395 98.5824 42.6386 96.1311 38.5791L73.7214 61.9235L59.9577 45.4014L48.0449 59.156L70.954 80.2645L101.524 54.0244C101.455 53.5525 101.382 53.0851 101.299 52.6224L101.29 52.627Z"
				fill="#09326C"
			/>
		</svg>
	);
};
