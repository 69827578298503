import React, { useRef, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss, Inline } from '@atlaskit/primitives';

type CommentAddPromptProps = {
	onClick: () => void;
	autofocusRef: { current: boolean | null };
};

const commentAddPromptContainerStyle = xcss({
	font: 'inherit',
	width: '100%',
	height: '40px',
	color: 'color.text.subtlest',
	display: 'inline-flex',
	verticalAlign: 'top',
	marginLeft: 'space.100',
	flexGrow: 1,
	backgroundColor: 'elevation.surface.overlay',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	paddingLeft: 'space.100',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		cursor: 'text',
	},
});

const i18n = defineMessages({
	commentPanelFooterPlaceholder: {
		id: 'comments-panel.comments.add.prompt-placeholder',
		defaultMessage: 'Add text, @mention others, or use / for elements',
		description: 'Placeholder on the comment panel footer elememt to add a comment',
	},
});

const commentAddPromptMessage = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	color: 'color.text.subtlest',
});

export const CommentAddPrompt: React.FC<CommentAddPromptProps> = ({
	onClick,
	autofocusRef,
}: CommentAddPromptProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	const { formatMessage } = useIntl();

	useEffect(() => {
		if (autofocusRef.current) {
			buttonRef.current?.focus();
			autofocusRef.current = false;
		}
	}, [autofocusRef]);

	return (
		<Box
			xcss={commentAddPromptContainerStyle}
			onClick={onClick}
			ref={buttonRef}
			data-testId="add-comment-button"
		>
			<Inline xcss={commentAddPromptMessage}>
				{formatMessage(i18n.commentPanelFooterPlaceholder)}
			</Inline>
		</Box>
	);
};
