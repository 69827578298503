import gql from 'graphql-tag';

export const ContentStatusQuery = gql`
	query ContentStatusQuery($contentId: ID!) {
		content(id: $contentId, status: ["current", "draft", "archived"]) {
			nodes {
				id
				status
				type
			}
		}
	}
`;
