import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { ExperienceAttributes } from './ExperienceEvent';
import {
	CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
	CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
	CREATE_INLINE_COMMENT_EXPERIENCE,
	CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
} from './ExperienceName';
import { getExperienceTracker } from './getExperienceTracker';
import { collectAll } from './ExperienceEventCollector';

let commonCreateInlineCommentExperiencesAttributes: ExperienceAttributes = {};
let runningExperiences: string[] = [];
// Note: The soft failure is set on the first soft failure not updated following
let softFailure: { error: Error; pointOfFailure: string } | undefined = undefined;
let publishFailed = false;
let publishSuccessCount: number = 0;

function getCreateInlineCommentExperienceDebug(experienceName: string) {
	function createInlineCommentExperienceDebug({
		error,
		createAnalyticsEvent,
		extraAttributes,
	}: {
		createAnalyticsEvent: CreateUIAnalyticsEvent;
		error: Error;
		extraAttributes?: ExperienceAttributes;
	}) {
		const experienceAttributes = getExperienceTracker().getExperienceState(
			CREATE_INLINE_COMMENT_EXPERIENCE,
		);
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'error',
				actionSubject: CREATE_INLINE_COMMENT_EXPERIENCE,
				source: experienceName,
				attributes: {
					...experienceAttributes,
					...commonCreateInlineCommentExperiencesAttributes,
					...extraAttributes,

					error: error.message || String(error),
					stack: error.stack,
				},
			},
		}).fire();
	}
	return createInlineCommentExperienceDebug;
}

/**
 * This is used for the create inline comment parent and children experiences,
 * and manages some internal state connecting the parent and child experiences.
 *
 * ```ts
 * // start the parent experience
 * createInlineCommentCompoundExperience.start()
 * ```
 *
 * ```ts
 * // attach attributes across all experiences
 * createInlineCommentCompoundExperience.addCommonAttributes({ example: 'value' })
 * ```
 *
 * ```ts
 * // start a child experience
 * createInlineCommentCompoundExperience.initExperience.start()
 * ```
 *
 * Each child experience has additional methods for completing, failing, and debugging.
 */
export const createInlineCommentCompoundExperience = {
	start: function createInlineCommentCompoundExperience({
		attributes,
	}: {
		attributes: /**
		 * Editor Notes: In the Editor -- we don't have access to an annotationId until
		 * later in the experience (on "publish").
		 */

		| {
					pageClass: 'editor';
					commentType: 'inline';
					annotationId?: undefined;
			  }
			| {
					pageClass: 'editor';
					commentType: 'block';
					blockType: 'media';
					annotationId?: undefined;
			  }
			| {
					pageClass: 'renderer';
					commentType: 'inline';
					annotationId: string;
			  }
			| {
					pageClass: 'renderer';
					commentType: 'block';
					blockType: 'media';
					annotationId: string;
			  };
	}) {
		// reset common experience state when starting a new parent experience
		runningExperiences = [CREATE_INLINE_COMMENT_EXPERIENCE];
		commonCreateInlineCommentExperiencesAttributes = {};
		softFailure = undefined;
		publishFailed = false;
		publishSuccessCount = 0;

		const experienceTracker = getExperienceTracker();

		experienceTracker.start({
			name: CREATE_INLINE_COMMENT_EXPERIENCE,
			attributes,
			collect: (events, experience) => {
				collectAll([
					CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
					CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
					CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
				])(events, experience);
			},
		});

		commonCreateInlineCommentExperiencesAttributes = {
			compoundTaskId: experienceTracker.getExperienceState(CREATE_INLINE_COMMENT_EXPERIENCE)
				?.taskId,
			annotationId: attributes.annotationId,
			pageClass: attributes.pageClass,
		};
	},
	debug: getCreateInlineCommentExperienceDebug(CREATE_INLINE_COMMENT_EXPERIENCE),
	addCommonAttributes: (commonAttributes: /**
	 * Explicitly typing the expected added attributes to mitigate
	 * unexpected usage.
	 */
	{
		inlineNodeTypes: string[];
		initialPosition?: [from: number, to: number];
	}) => {
		const experienceTracker = getExperienceTracker();

		commonCreateInlineCommentExperiencesAttributes = {
			...commonCreateInlineCommentExperiencesAttributes,
			...commonAttributes,
		};
		for (const runningExperience of runningExperiences) {
			experienceTracker.updateAttributes({
				name: runningExperience,
				attributes: commonCreateInlineCommentExperiencesAttributes,
			});
		}
	},
	attachCommonAttributes: (experienceName: string) => {
		getExperienceTracker().updateAttributes({
			name: experienceName,
			attributes: commonCreateInlineCommentExperiencesAttributes,
		});
	},
	initExperience: {
		start: () => {
			runningExperiences.push(CREATE_INLINE_COMMENT_INIT_EXPERIENCE);
			const experienceTracker = getExperienceTracker();

			experienceTracker.start({
				name: CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
				timeout: 5000,
				attributes: {
					...commonCreateInlineCommentExperiencesAttributes,
				},
			});
			createInlineCommentCompoundExperience.attachCommonAttributes(
				CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
			);
		},

		fail: (error: Error) => {
			const experienceTracker = getExperienceTracker();
			experienceTracker.fail({
				name: CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
				error,
			});
		},
		softFail: (error: Error) => {
			const experienceTracker = getExperienceTracker();

			if (!softFailure) {
				softFailure = { error, pointOfFailure: 'init' };
			}

			experienceTracker.succeed({
				name: CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
				attributes: {
					softFailure: error.message,
				},
			});
		},
		/**
		 * This completes the init experience and starts the draft to publish experience.
		 */
		complete: () => {
			const experienceTracker = getExperienceTracker();

			experienceTracker.succeed({
				name: CREATE_INLINE_COMMENT_INIT_EXPERIENCE,
			});
		},
		debug: getCreateInlineCommentExperienceDebug(CREATE_INLINE_COMMENT_INIT_EXPERIENCE),
	},
	draftToPublishExperience: {
		start: () => {
			runningExperiences.push(CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE);
			const experienceTracker = getExperienceTracker();
			experienceTracker.start({
				name: CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
				attributes: commonCreateInlineCommentExperiencesAttributes,
			});
			createInlineCommentCompoundExperience.attachCommonAttributes(
				CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
			);
		},
		publishFailed: (error: Error) => {
			publishFailed = true;
			if (!softFailure) {
				softFailure = { error, pointOfFailure: 'publish-failed' };
			}
		},
		publishSucceeded: () => {
			publishSuccessCount += 1;
		},
		dismissed: () => {
			const experienceTracker = getExperienceTracker();
			if (publishSuccessCount > 0) {
				experienceTracker.fail({
					name: CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
					error: new Error('Draft comment dismissed after publish success'),
				});
			} else if (publishFailed) {
				experienceTracker.fail({
					name: CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
					error: new Error('Draft comment dismissed after publish attempt'),
				});
			} else {
				experienceTracker.abort({
					name: CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
					reason: 'Draft comment dismissed',
				});
			}
		},
		softFail: (error: Error) => {
			const experienceTracker = getExperienceTracker();

			if (!softFailure) {
				softFailure = { error, pointOfFailure: 'multiple-saves-without-error' };
			}

			experienceTracker.succeed({
				name: CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
				attributes: {
					softFailure: error.message,
				},
			});
		},
		complete: () => {
			const experienceTracker = getExperienceTracker();
			if (publishSuccessCount > 1) {
				softFailure = {
					error: new Error('multiple-publish-success'),
					pointOfFailure: 'multiple-publish-success',
				};
			}
			experienceTracker.succeed({
				name: CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE,
				attributes: {
					softFailure: 'multiple-publish-success',
				},
			});
		},
		debug: getCreateInlineCommentExperienceDebug(CREATE_INLINE_COMMENT_DRAFT_TO_PUBLISH_EXPERIENCE),
	},
	attachCommentExperience: {
		start: () => {
			runningExperiences.push(CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE);
			const experienceTracker = getExperienceTracker();
			experienceTracker.start({
				name: CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
				// If the view comment does not load in 6 seconds, it's
				// an indicator something has gone wrong.
				timeout: 6000,
				attributes: commonCreateInlineCommentExperiencesAttributes,
			});
			createInlineCommentCompoundExperience.attachCommonAttributes(
				CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
			);
		},
		fail: (error: Error, attributes?: ExperienceAttributes) => {
			const experienceTracker = getExperienceTracker();
			experienceTracker.fail({
				name: CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
				error,
				attributes,
			});
		},
		complete: () => {
			const experienceTracker = getExperienceTracker();
			if (softFailure) {
				experienceTracker.fail({
					name: CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
					error: softFailure.error,
					attributes: {
						pointOfFailure: softFailure.pointOfFailure,
					},
				});
				return;
			}
			experienceTracker.succeed({
				name: CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE,
			});
		},
		debug: getCreateInlineCommentExperienceDebug(CREATE_INLINE_COMMENT_ATTACH_COMMENT_EXPERIENCE),
	},
};
