/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values */
import React from 'react';

import Spinner from '@atlaskit/spinner';
import { Stack, xcss } from '@atlaskit/primitives';

const loadingBoxStyles = xcss({
	justifyContent: 'center',
	flexGrow: 1,
});

export const Loading: React.FC = () => {
	return (
		<Stack alignInline="center" space="space.1000" xcss={loadingBoxStyles}>
			<Spinner testId="loading-spinner" interactionName="load" label="Loading" size="large" />
		</Stack>
	);
};
