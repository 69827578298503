import React from 'react';

export const EmptyOpenViewSVG = () => {
	return (
		<svg
			width="127"
			height="126"
			viewBox="0 0 127 126"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M106.207 40.3301H20.793V92.3429H106.207V40.3301Z" fill="#DDDEE1" />
			<path
				d="M63.5002 89.1211L51.5462 91.105C51.5462 91.105 52.6963 96.5711 49.9609 101.497C50.3275 101.529 50.694 101.552 51.0697 101.552C57.9379 101.552 63.5002 95.9847 63.5002 89.1211Z"
				fill="#DDDEE1"
			/>
			<path
				d="M102.148 43.624C102.331 42.5977 102.427 41.5072 102.427 40.3526C102.427 39.1979 102.331 38.0754 102.143 37.0399C101.034 30.859 96.7366 27.047 90.7161 26.0665C89.6898 25.9015 88.613 25.8145 87.4905 25.8145C86.3221 25.8145 85.1996 25.9061 84.132 26.0848C78.1069 27.0928 73.8184 30.8911 72.705 37.0399C72.5171 38.08 72.4209 39.1842 72.4209 40.3526C72.4209 41.5072 72.5171 42.5977 72.7004 43.624C73.8046 49.7636 78.0932 53.5711 84.132 54.5791C85.1996 54.7578 86.3221 54.8494 87.4905 54.8494C88.613 54.8494 89.6898 54.7624 90.7161 54.5974C96.7458 53.6169 101.048 49.8003 102.148 43.624Z"
				fill="#1868DB"
			/>
			<path
				d="M102.148 43.624C102.331 42.5976 102.427 41.5072 102.427 40.3525H72.4209C72.4209 41.5072 72.5171 42.5976 72.7004 43.624C73.8046 49.7636 78.0932 53.5711 84.132 54.5791C85.1996 54.7578 86.3221 54.8494 87.4905 54.8494C88.613 54.8494 89.6898 54.7624 90.7161 54.5974C96.7458 53.6169 101.048 49.8002 102.148 43.624Z"
				fill="#09326C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M97.1444 42.7668H77.6992V39.5596H97.1444V42.7668Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M85.8184 50.8863V31.4365H89.0256V50.8863H85.8184Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M95.5865 61.3508H31.4136V58.1436H95.5865V61.3508Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M95.5865 70.217H31.4136V67.0098H95.5865V70.217Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M79.3577 79.0784H31.4136V75.8711H79.3577V79.0784Z"
				fill="white"
			/>
		</svg>
	);
};
