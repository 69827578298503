import React, { memo, useLayoutEffect, useState, type FC } from 'react';

import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner/spinner';

import { fg } from '@confluence/feature-gating';

import { BATCH_SIZE, type SharedCommentBatchProps } from './CommentBatchList';

const spinnerContainerStyles = xcss({
	margin: 'auto',
	position: 'sticky',
	bottom: '1px',
});

// Exported for testing
export const SPINNER_DURATION = 100;

type CommentBatchProps = SharedCommentBatchProps & {
	commentsList: any[];
	canRenderBatch: boolean;
	batchIndex: number;
	onBatchRendered: (batchIndex: number) => void;
	transitionIndexWithinBatch: number | undefined;
};

export const CommentBatch: FC<CommentBatchProps> = memo(
	({
		passThroughProps,
		Component,
		getElementsToBatch,
		commentsList,
		canRenderBatch,
		batchIndex,
		onBatchRendered,
		transitionIndexWithinBatch,
	}) => {
		const [showSpinner, setShowSpinner] = useState(false);

		useLayoutEffect(() => {
			if (canRenderBatch) {
				setShowSpinner(true);
				setTimeout(() => {
					onBatchRendered(batchIndex);
					setShowSpinner(false);
				}, SPINNER_DURATION);
			}
		}, [canRenderBatch, batchIndex, onBatchRendered]);

		if (!canRenderBatch) {
			return null;
		}

		const batchOffset = batchIndex * BATCH_SIZE;
		const batchedList = commentsList.slice(batchOffset, batchOffset + BATCH_SIZE);

		const elements = getElementsToBatch(
			batchedList,
			passThroughProps,
			Component,
			transitionIndexWithinBatch,
		);

		return (
			<>
				{elements}
				{showSpinner ? (
					<Flex justifyContent="center" alignItems="center" xcss={spinnerContainerStyles}>
						<Spinner
							interactionName={
								fg('ufo-manual-experimental-holds-confluence') ? 'comment-batch' : undefined
							}
							size="medium"
						/>
					</Flex>
				) : null}
			</>
		);
	},
);
