import React, { useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import {
	useCommentsData,
	useActiveInlineCommentsQuery,
	useActiveCommentsQuery,
} from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';
import type { FlagsStateContainer } from '@confluence/flags';
import {
	getOpenCommentThreads,
	getAnnotationsToLoad,
	useCommentsPanel,
	ViewValues,
} from '@confluence/comments-panel-utils';

import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';
import { EmptyStateComponent } from '../components/EmptyStateComponent';
import { CommentsPanelList } from '../components/CommentsPanelList';
import { EmptyOpenViewSVG } from '../assets/EmptyOpenView';

type OpenViewProps = {
	showDeleteOption: boolean;
	flags?: FlagsStateContainer;
};

const i18n = defineMessages({
	emptyCommentsHeaderText: {
		id: 'comments-panel.empty.open.comments.header.text',
		defaultMessage: 'Be the first to comment',
		description:
			'Header text to display in Open View empty state when there are no open comments on the page.',
	},
	emptyCommentsBodyText: {
		id: 'comments-panel.empty.open.comments.body.text',
		defaultMessage:
			'Highlight some text and select {highlighted} or add a comment at the bottom of this panel.',
		description:
			'Body text to display in Open View empty state when there are no Open comments on the page.',
	},
});

// TODO: Update this to use page comments when they are supported
export const OpenView = ({ showDeleteOption, flags }: OpenViewProps) => {
	const [
		{ orderedActiveAnnotationIdList, commentsDataMap, removedThreadsMap },
		{ clearRemovedComments },
	] = useCommentsData();
	const [
		{ initialDataLoadedForViewMap, showInlineComments, showGeneralComments },
		{ setInitialDataLoadedForView },
	] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[ViewValues.OPEN];

	const { formatMessage } = useIntl();

	const [contentId] = usePageContentId();

	const annotationsToLoad = getAnnotationsToLoad(orderedActiveAnnotationIdList);

	const commentsQueryHook = fg('confluence_frontend_comments_panel_v2')
		? useActiveCommentsQuery
		: useActiveInlineCommentsQuery;
	const commentsQueryHookVariables: any = fg('confluence_frontend_comments_panel_v2')
		? {
				pageId: contentId || '',
			}
		: {
				pageId: contentId || '',
				markerRefList: annotationsToLoad,
				// skip entirely if no unresolved comments on the page
				skip: annotationsToLoad.length === 0,
			};

	const { loading, error, refetch } = commentsQueryHook(commentsQueryHookVariables);

	useEffect(() => {
		// When this view unmounts, we want to clear out the "resolved"/"deleted" comments
		return () => {
			clearRemovedComments();
		};
	}, [clearRemovedComments]);

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: ViewValues.OPEN });
		}
	}, [loading, setInitialDataLoadedForView]);

	const commentThreads = useMemo(() => {
		if (
			(!isInitialCommentDataLoaded && loading) ||
			(orderedActiveAnnotationIdList.length === 0 && Object.keys(removedThreadsMap).length === 0)
		) {
			return [];
		}

		return getOpenCommentThreads({
			commentsDataMap,
			orderedActiveAnnotationIdList,
			removedThreadsMap,
			showInlineComments,
			showGeneralComments,
		});
	}, [
		loading,
		isInitialCommentDataLoaded,
		commentsDataMap,
		orderedActiveAnnotationIdList,
		removedThreadsMap,
		showInlineComments,
		showGeneralComments,
	]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <Loading />;
		}

		if (error) {
			return (
				<ErrorPanel
					error={error}
					onRetryClick={async () => {
						await refetch();
					}}
				/>
			);
		}
	}

	if (commentThreads.length === 0) {
		return (
			<EmptyStateComponent
				SVG={EmptyOpenViewSVG}
				emptyCommentsHeaderText={formatMessage(i18n.emptyCommentsHeaderText)}
				emptyCommentsBodyText={formatMessage(i18n.emptyCommentsBodyText, {
					highlighted: <b>Comment</b>,
				})}
				isOpenView
			/>
		);
	}

	return (
		<CommentsPanelList
			commentThreads={commentThreads}
			supportedTopLevelActions={
				showDeleteOption ? ['edit', 'resolve', 'delete'] : ['edit', 'resolve']
			}
			flags={flags}
		/>
	);
};
