import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl-next';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';

import { fg } from '@confluence/feature-gating';
import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';
import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageMode, ContentStatus } from '@confluence/page-utils/entry-points/enums';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useContentStatus } from '@confluence/page-utils/entry-points/useContentStatus';
import { useAnnotationsDispatch } from '@confluence/annotation-provider-store';
import { useCommentsPanel, type ViewValues } from '@confluence/comments-panel-utils';
import { usePageInfo } from '@confluence/page-info';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';
import { isSpaceOverview } from '@confluence/named-routes';

import { CommentsPanelFilterMenu } from './CommentsPanelFilterMenu';
import { CommentsPanelFooter } from './CommentsPanelFooter';
import { CommentsPanelHeaderIcon } from './CommentsPanelHeaderIcon';
import { CommentsPanelWrapper } from './CommentsPanelWrapper';
import { useUnreadPanelComments } from './hooks/useUnreadPanelComments';

export const i18n = defineMessages({
	commentsPanelHeaderLabel: {
		id: 'comments-panel.panel.header.label',
		defaultMessage: 'Comments',
		description: 'Label for the comments panel header',
	},
});

export const useShowCommentsPanel = () => {
	const isSpaceOverviewPage = isSpaceOverview();
	const { handleMarkCommentsAsRead } = useUnreadPanelComments();
	const { contentStatus } = useContentStatus();
	const [shouldClearUnreads, setShouldClearUnreads] = useState<boolean>(false);
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar, hideObjectSidebar }] =
		useObjectSidebar();

	const { setViewProps, setEditorViewProps } = useAnnotationsDispatch();

	const [, { setCurrentView, setCurrentlySelectedCommentMarkerRef }] = useCommentsPanel();
	const { pageInfo } = usePageInfo();
	const { canAddComments } = useAddCommentPermissionCheck(pageInfo?.id ?? '', true);

	const pageMode = useGetPageMode();
	const eventEmitter =
		pageMode === PageMode.VIEW
			? getRendererAnnotationEventEmitter()
			: getEditorAnnotationEventEmitter();

	// Required to invoke handleMarkCommentsAsRead and if any commentsMarkedAsRead
	// then we will call the MarkCommentsReadMutation Mutation
	useEffect(() => {
		if (shouldClearUnreads) {
			handleMarkCommentsAsRead();
		}
		return () => {
			setShouldClearUnreads(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldClearUnreads, setShouldClearUnreads]);

	const onPanelClose = () => {
		setCurrentView(undefined);
		setShouldClearUnreads(true);

		// Unselect active annotations on the page
		if (pageMode === PageMode.VIEW) {
			eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
			eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
			setViewProps(null);
		} else {
			eventEmitter.emit('setselectedannotation', '');
			setEditorViewProps(null);
		}

		// clear selected comment thread in the comments panel
		setCurrentlySelectedCommentMarkerRef('');
	};

	const canClosePanel = () => {
		// TODO: Check if the content has changed before we allow the closing of the panel
		return true;
	};

	const showCommentsPanel = ({ openWithView }: { openWithView?: ViewValues } = {}) => {
		showObjectSidebar(
			{
				id: PanelName.CommentsPanel,
				headerComponentElements: {
					HeaderIcon: fg('confluence_frontend_comments_panel_v2')
						? CommentsPanelHeaderIcon
						: undefined,
					headerLabel: i18n.commentsPanelHeaderLabel,
					HeaderRightAlignedElement: fg('confluence_frontend_comments_panel_v2')
						? CommentsPanelFilterMenu
						: undefined,
				},
				BodyComponent: () => CommentsPanelWrapper({ openWithView }),
				FooterComponent:
					canAddComments &&
					contentStatus !== ContentStatus.DRAFT &&
					!isSpaceOverviewPage &&
					fg('confluence_frontend_comments_panel_v2')
						? CommentsPanelFooter
						: undefined,
				closeOptions: {
					canClosePanel,
					onPanelClose,
				},
			},
			'push',
		);
	};

	// NOTE: Exposing the hide function for now while we have the CommentButton
	return {
		isCommentsPanelShown: isObjectSidebarShown && panel?.id === PanelName.CommentsPanel,
		showCommentsPanel,
		hideCommentsPanel: hideObjectSidebar,
	};
};
