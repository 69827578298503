import { COMMENT_BATCH_SIZE, CommentActionType } from '@confluence/comments-data';
import type {
	AnnotationStatus,
	CommentData,
	CommentsDataMap,
	RemovedThreadsCommentTypeMap,
} from '@confluence/comments-data';

export const scrollCommentsPanel = ({
	containerId,
	commentMarkerRef,
}: {
	containerId: string;
	commentMarkerRef: string;
}) => {
	const container = document.querySelector(`[data-testid='${containerId}']`);
	if (container) {
		const element = document.querySelector(`[data-testid='${commentMarkerRef}']`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}
};

export const scrollCommentsPanelToThread = ({
	threadKey,
	useSmoothScroll,
}: {
	threadKey: string;
	useSmoothScroll?: boolean;
}) => {
	const scrollingContainer = document.getElementById('comments-panel-list-container');

	if (scrollingContainer) {
		const threadToScrollTo = document.getElementById(`comment-thread-${threadKey}-container`);

		if (threadToScrollTo) {
			scrollingContainer.scrollTo({
				// TODO: This was mostly written for a new parent comment, need to add reply handler for future use
				top: threadToScrollTo.offsetTop + threadToScrollTo.scrollHeight,
				behavior: useSmoothScroll ? 'smooth' : 'instant',
			});
		}
	}
};

export const getAnnotationsToLoad = (orderedActiveAnnotationIdList: AnnotationStatus[]) => {
	return orderedActiveAnnotationIdList
		.slice(0, Math.min(orderedActiveAnnotationIdList.length, COMMENT_BATCH_SIZE))
		.filter((item) => !item.isLoaded) // only get the ones that are not loaded yet
		.map((item) => item.threadKey);
};

// organize a list of open comment threads that we have data for
export const getOpenCommentThreads = ({
	commentsDataMap,
	orderedActiveAnnotationIdList,
	removedThreadsMap,
	showInlineComments,
	showGeneralComments,
}: {
	commentsDataMap: CommentsDataMap;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedThreadsMap: RemovedThreadsCommentTypeMap;
	showInlineComments: boolean;
	showGeneralComments: boolean;
}) => {
	const openCommentThreads: CommentData[] = [];

	// inline comments
	if (showInlineComments) {
		const removedAnnotationIds = new Set(Object.values(removedThreadsMap.inline));
		const filteredActiveAnnotationIds = orderedActiveAnnotationIdList.filter(
			(item) => !removedAnnotationIds.has(item.threadKey),
		);
		const totalAnnotationsCount =
			filteredActiveAnnotationIds.length + Object.keys(removedThreadsMap).length;

		let activeIndex = 0; // Track position in filteredActiveAnnotationIds

		for (let i = 0; i < totalAnnotationsCount; i++) {
			let threadKey = '';

			if (removedThreadsMap.inline.hasOwnProperty(i)) {
				threadKey = removedThreadsMap.inline[i];
				const commentThread = commentsDataMap.inline[threadKey];
				if (commentThread) {
					openCommentThreads.push(commentThread);
				}
			} else if (activeIndex < filteredActiveAnnotationIds.length) {
				threadKey = filteredActiveAnnotationIds[activeIndex].threadKey;
				const commentThread = commentsDataMap.inline[threadKey];
				if (commentThread?.isOpen) {
					openCommentThreads.push(commentThread);
				}
				activeIndex++;
			}
		}
	}

	// general comments
	if (showGeneralComments) {
		// We can just push these in the order we have them because we have no concept of "active"
		const generalComments = Object.values(commentsDataMap.general).filter((generalComment) => {
			return (
				generalComment.isOpen ||
				generalComment.wasRemovedByAnotherUser === CommentActionType.DELETE_COMMENT
			);
		});
		openCommentThreads.push(...generalComments);
	}

	return openCommentThreads;
};

// organize a list of unread comment threads that we have data for
export const getUnreadCommentThreads = ({
	numUnreadComments,
	commentsDataMap,
	orderedActiveAnnotationIdList,
	removedThreadsMap,
	showInlineComments,
	showGeneralComments,
}: {
	numUnreadComments: number;
	commentsDataMap: CommentsDataMap;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedThreadsMap: RemovedThreadsCommentTypeMap;
	showInlineComments: boolean;
	showGeneralComments: boolean;
}) => {
	const unreadCommentThreads: CommentData[] = [];

	if (
		(Object.keys(commentsDataMap.inline).length > 0 ||
			Object.keys(commentsDataMap.general).length > 0) &&
		(numUnreadComments > 0 || Object.keys(removedThreadsMap.inline).length > 0)
	) {
		// inline comments
		if (showInlineComments) {
			const removedAnnotationIds = new Set(Object.values(removedThreadsMap.inline));
			const filteredActiveAnnotationIds = orderedActiveAnnotationIdList.filter(
				(item) => !removedAnnotationIds.has(item.threadKey),
			);
			const totalAnnotationsCount =
				filteredActiveAnnotationIds.length + Object.keys(removedThreadsMap.inline).length;

			let activeIndex = 0; // Track position in filteredActiveAnnotationIds

			for (let i = 0; i < totalAnnotationsCount; i++) {
				let threadKey = '';

				if (removedThreadsMap.inline.hasOwnProperty(i)) {
					threadKey = removedThreadsMap.inline[i];
					const commentThread = commentsDataMap.inline[threadKey];
					if (commentThread?.isUnread || commentThread?.replies?.some((reply) => reply.isUnread)) {
						unreadCommentThreads.push(commentThread);
					}
				} else if (activeIndex < filteredActiveAnnotationIds.length) {
					threadKey = filteredActiveAnnotationIds[activeIndex].threadKey;
					const commentThread = commentsDataMap.inline[threadKey];
					if (commentThread?.isUnread || commentThread?.replies?.some((reply) => reply.isUnread)) {
						unreadCommentThreads.push(commentThread);
					}
					activeIndex++;
				}
			}
		}

		// general comments
		if (showGeneralComments) {
			// We can just push these in the order we have them because we have no concept of "active"
			const generalComments = Object.values(commentsDataMap.general).filter((generalComment) => {
				return (
					(generalComment.isOpen ||
						generalComment.wasRemovedByAnotherUser === CommentActionType.DELETE_COMMENT) &&
					(generalComment.isUnread || generalComment.replies.some((reply) => reply.isUnread))
				);
			});
			unreadCommentThreads.push(...generalComments);
		}
	}

	return unreadCommentThreads;
};

export const getResolvedCommentThreads = ({
	commentsDataMap,
	showInlineComments,
	showGeneralComments,
}: {
	commentsDataMap: CommentsDataMap;
	showInlineComments: boolean;
	showGeneralComments: boolean;
}) => {
	const resolvedCommentThreads: CommentData[] = [];
	// inline comments
	if (showInlineComments) {
		for (const key in commentsDataMap.inline) {
			const comment = commentsDataMap.inline[key];
			if (comment.isOpen === false) {
				resolvedCommentThreads.push(comment);
			}
		}
	}

	// general comments
	if (showGeneralComments) {
		for (const key in commentsDataMap.general) {
			const comment = commentsDataMap.general[key];
			if (comment.isOpen === false) {
				resolvedCommentThreads.push(comment);
			}
		}
	}

	return resolvedCommentThreads;
};
