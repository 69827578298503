import React, { memo, useCallback, useMemo, useState, type FC, type ComponentType } from 'react';

import { usePageContentId } from '@confluence/page-context';
import { CommentType } from '@confluence/comments-data';

import { CommentBatch } from './CommentBatch';

export const BATCH_SIZE = 8;

// Binary search to find the transition point between inline/general comments
const findTransitionIndex = (commentsList) => {
	if (!commentsList.length) return 0;

	let low = 0;
	let high = commentsList.length - 1;

	// If all comments are of the same type, return 0
	const firstType = commentsList[0].type;
	const lastType = commentsList[high].type;
	if (firstType === lastType) return 0;

	while (low < high) {
		const mid = Math.floor((low + high) / 2);
		if (commentsList[mid].type === CommentType.INLINE) {
			low = mid + 1;
		} else {
			high = mid;
		}
	}
	return low;
};

// TODO: Convert any to generics
export type SharedCommentBatchProps = {
	passThroughProps: any;
	Component: ComponentType<any>;
	getElementsToBatch: (
		batchedList: any[],
		passThroughProps: any,
		Component: ComponentType<any>,
		transitionIndexWithinBatch: number | undefined,
	) => React.JSX.Element[];
};

type CommentBatchListProps = SharedCommentBatchProps & {
	commentsList: any[];
};

export const CommentBatchList: FC<CommentBatchListProps> = memo(
	({ commentsList, passThroughProps, Component, getElementsToBatch }) => {
		const [contentId] = usePageContentId();

		const batchCount = Math.ceil(commentsList.length / BATCH_SIZE);
		const batches = useMemo(() => new Array(batchCount).fill(0), [batchCount]);
		const [batch, setBatch] = useState(0);

		const onBatchRendered = useCallback((batchIndex) => {
			setBatch(batchIndex + 1);
		}, []);

		const globalTransitionIndex = useMemo(() => findTransitionIndex(commentsList), [commentsList]);
		const transitionBatchIndex = Math.floor(globalTransitionIndex / BATCH_SIZE);
		const transitionIndexWithinBatch = globalTransitionIndex % BATCH_SIZE;

		return (
			<>
				{batches.map((_, index) => {
					const isTransitionBatch = index === transitionBatchIndex && globalTransitionIndex !== 0;
					return (
						<CommentBatch
							key={`${contentId}-batch-${index}`}
							commentsList={commentsList}
							canRenderBatch={index <= batch}
							batchIndex={index}
							passThroughProps={passThroughProps}
							Component={Component}
							getElementsToBatch={getElementsToBatch}
							onBatchRendered={onBatchRendered}
							transitionIndexWithinBatch={
								isTransitionBatch ? transitionIndexWithinBatch : undefined
							}
						/>
					);
				})}
			</>
		);
	},
);
