import gql from 'graphql-tag';

export const DeleteGeneralCommentMutation = gql`
	mutation DeleteGeneralCommentMutation(
		$commentIdToDelete: ID!
		$deleteFrom: CommentDeletionLocation
	) {
		deleteComment(commentIdToDelete: $commentIdToDelete, deleteFrom: $deleteFrom)
	}
`;
