import gql from 'graphql-tag';

export const ResolveCommentMutation = gql`
	mutation ResolveCommentMutation($commentIds: [ID]!, $cloudId: ID!) {
		confluence_resolveComments(commentIds: $commentIds, cloudId: $cloudId) {
			success
			commentResolutionStates {
				commentId
				resolveProperties {
					resolvedTime
					resolvedUser
					resolvedFriendlyDate
				}
				status
			}
			errors {
				message
				extensions {
					... on ResolveCommentsMutationErrorExtension {
						errorType
						statusCode
					}
					__typename
				}
			}
		}
	}
`;
