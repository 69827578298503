import type { FC } from 'react';
import React, { useContext, useRef } from 'react';
import { styled } from '@compiled/react';
import { useMutation } from '@apollo/react-hooks';
import set from 'lodash/set';

import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { DocNode } from '@atlaskit/adf-schema';
import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';

import {
	EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
	EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentEditor } from '@confluence/comment';
import type { CommentType } from '@confluence/comments-data';
import { useCommentsData, CommentType as CommentTypeEnum } from '@confluence/comments-data';
import { ReactionsContext, useCommentsContentActions } from '@confluence/comment-context';
import { useSessionData } from '@confluence/session-data';
import {
	UpdateInlineCommentMutation,
	InlineCommentQuery,
	CommentsPanelQuery,
	type CommentsPanelQueryType,
	type InlineCommentQueryType,
} from '@confluence/inline-comments-queries';
import { getLogger } from '@confluence/logger';
import { END } from '@confluence/navdex';
import { updateFooterApolloCacheOnEdit } from '@confluence/page-comments-queries/entry-points/pageCommentUtils';

import type { InlineCommentsMode } from './inlineCommentsTypes';
import { parseError, getTranslatedError } from './inlineCommentsErrorUtils';
import { CommentAuthor } from './CommentAuthor';

const logger = getLogger('inline-comments-common:edit-comment');

type EditCommentProps = {
	pageId: string;
	pageType: string;
	annotationId: string;
	commentId: string;
	spaceId: string;
	content: string;
	displayCommentInViewMode: () => void;
	isReply: boolean;
	avatarUrl: string;
	displayName: string;
	mode: InlineCommentsMode;
	hasMediaUploadPermissions: boolean;
	isCommentsPanel?: boolean;
	onCancelEdit?: () => void;
	/**
	 * Return a list of inline node types, which are wrapped by the annotation,
	 * for annotation with given ID.
	 *
	 * The `undefined` will be returned if `editor_inline_comments_on_inline_nodes` is off.
	 *
	 * @todo: Do not forget to remove `undefined` when the
	 *        `editor_inline_comments_on_inline_nodes` is removed.
	 */
	getInlineNodeTypes?: (annotationId: string) => string[] | undefined;
	commentType: CommentType;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditContainer = styled.div({
	padding: `${token('space.150')} ${token('space.200')}`,
});

export const EditComment: FC<EditCommentProps> = ({
	pageId,
	pageType,
	annotationId,
	commentId,
	spaceId,
	content,
	displayCommentInViewMode,
	isReply,
	avatarUrl,
	displayName,
	mode,
	hasMediaUploadPermissions,
	isCommentsPanel,
	onCancelEdit,
	getInlineNodeTypes,
	commentType,
}) => {
	const { userId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { onChange, resetContentChanged } = useCommentsContentActions();
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const [, { updateCommentBody }] = useCommentsData();
	const commentContainerElementRef = useRef<HTMLDivElement | null>(null);

	const [updateCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UpdateInlineCommentMutation,
	);

	const handleOnEditorReady = () => {
		experienceTracker.succeed({
			name: EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
		});
	};

	const handleOnCancel = () => {
		displayCommentInViewMode();
		resetContentChanged();
		onCancelEdit && onCancelEdit();
	};

	const handleUpdateComment = (adf: JSONDocNode, onSuccess: () => void) => {
		experienceTracker.start({
			name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			attributes: {
				mode: 'edit',
			},
		});

		const adfString = JSON.stringify(adf);
		const variables = {
			input: {
				commentId,
				commentBody: {
					value: adfString,
					representationFormat: 'ATLAS_DOC_FORMAT',
				},
			},
		};

		return updateCommentFn({
			variables,
			update: (cache) => {
				// readQuery can still return null which complicates TS lint errors with optional chaining
				// once we move to v3 of the apollo client we can move to cache.modify
				try {
					const dataProxy = cache.readQuery({
						query: isCommentsPanel ? CommentsPanelQuery : InlineCommentQuery,
						variables: isCommentsPanel
							? {
									pageId,
								}
							: {
									pageId,
									annotationId,
									contentStatus: ['DRAFT', 'CURRENT'],
								},
					});

					type QueryType = CommentsPanelQueryType | InlineCommentQueryType;

					const data: QueryType = Object.assign({} as any, dataProxy);

					let oldComment;
					// Standalone only has 1 comment so use index 0
					const idxToUpdate = isCommentsPanel
						? data?.comments?.nodes?.findIndex((n) => n?.id === commentId) || -1
						: 0;

					if (idxToUpdate === -1) {
						return;
					}

					// find the comment to update for a reply or the parent comment
					if (isReply) {
						// @ts-ignore
						const replies = data?.comments?.nodes[idxToUpdate].replies ?? [];

						replies.forEach((reply) => {
							// @ts-ignore
							if (reply?.id === commentId) {
								oldComment = reply;
							}
						});
					} else {
						// @ts-ignore
						oldComment = data?.comments?.nodes[idxToUpdate];
					}

					if (oldComment) {
						set(oldComment, 'body.value', adfString);
					}

					cache.writeQuery({
						query: isCommentsPanel ? CommentsPanelQuery : InlineCommentQuery,
						variables: isCommentsPanel
							? { pageId }
							: {
									pageId,
									annotationId,
									contentStatus: ['DRAFT', 'CURRENT'],
								},
						data,
					});
				} catch (error) {
					logger.error`An Error occurred when updating cache for edit reply in panel or inline - ${error}`;
				}

				if (commentType === CommentTypeEnum.GENERAL) {
					updateFooterApolloCacheOnEdit(commentId, adfString, pageId, isReactionsEnabled, cache);
				}
			},
		})
			.then(() => {
				experienceTracker.succeed({
					name: EDIT_INLINE_COMMENT_EXPERIENCE,
					attributes: {
						mode: 'edit',
						type: isReply ? 'reply' : 'topLevel',
					},
				});

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'updated',
						actionSubject: 'comment',
						actionSubjectId: commentId,
						objectType: pageType,
						objectId: pageId,
						source: 'editPageScreen',
						attributes: {
							commentType,
							pageType,
							mode: 'edit',
							navdexPointType: END,
							inlineNodeTypes: getInlineNodeTypes ? getInlineNodeTypes(annotationId) : undefined,
						},
					},
				}).fire();

				// Update the comment in the comments data store
				updateCommentBody(commentId, annotationId, adfString, commentType);

				// reset the editor
				onSuccess();

				resetContentChanged();

				displayCommentInViewMode();
			})
			.catch((error) => {
				const { message } = parseError(error);

				experienceTracker.stopOnError({
					name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
					error,
				});

				return Promise.reject({
					error: getTranslatedError(message, commentId),
				});
			});
	};

	let parsedContent: DocNode | undefined = undefined;
	try {
		parsedContent = JSON.parse(content);
	} catch (e) {
		logger.error`Unable to parse content ADF, ${e}`;
	}

	return (
		<EditContainer data-cy="editor-edit-container" ref={commentContainerElementRef}>
			<CommentAuthor
				commentMode="edit"
				userId={userId}
				displayName={displayName}
				avatarUrl={avatarUrl}
			/>
			<CommentEditor
				pageId={pageId}
				pageType={pageType}
				spaceId={spaceId}
				appearance="chromeless"
				EditorComponent={ChromelessEditor}
				commentMode="edit"
				commentType={mode === 'view' ? 'inline' : 'edit-inline'}
				onSaveComment={handleUpdateComment}
				content={parsedContent}
				onCancelComment={handleOnCancel}
				onContentChange={onChange}
				showCancelButton
				useNewWarningModal
				onEditorReady={handleOnEditorReady}
				hideWatchCheckbox
				pageMode={mode}
				hasMediaUploadPermissions={hasMediaUploadPermissions}
				commentContainerElementRef={commentContainerElementRef}
			/>
		</EditContainer>
	);
};
