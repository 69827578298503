import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';

import type { ResolvedInlineCommentsQueryType } from '@confluence/inline-comments-queries';
import { ResolvedInlineCommentsQuery } from '@confluence/inline-comments-queries';
import {
	updateCommentsDataState,
	useCommentsData,
	useResolvedCommentsQuery,
} from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';
import { useUnreadComments } from '@confluence/unread-comments';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useSessionData } from '@confluence/session-data';
import { useEditorAnnotations } from '@confluence/inline-comments-hooks';
import type { FlagsStateContainer } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';
import {
	useCommentsPanel,
	ViewValues,
	getResolvedCommentThreads,
} from '@confluence/comments-panel-utils';

import { CommentsPanelList } from '../components/CommentsPanelList';
import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';
import { EmptyResolvedSVG } from '../assets/EmptyResolvedSVG';
import { EmptyStateComponent } from '../components/EmptyStateComponent';

type ResolvedViewProps = {
	flags?: FlagsStateContainer;
};

const i18n = defineMessages({
	emptyCommentsHeaderText: {
		id: 'comments-panel.resolved.view.empty.header.text',
		defaultMessage: 'No resolved comments',
		description:
			'Header text to display in Resolved View empty state when there are no resolved comments on the page.',
	},
	emptyCommentsBodyText: {
		id: 'comments-panel.resolved.view.empty.text',
		defaultMessage:
			'Got comments that aren’t needed? Resolve them! They’ll display here, so you can reopen and add them back in the future.',
		description:
			'Body text to display in Resolved View empty state when there are no resolved comments on the page.',
	},
});

export const ResolvedView = ({ flags }: ResolvedViewProps) => {
	const [loading, setLoading] = useState(true);
	const { formatMessage } = useIntl();

	const [contentId] = usePageContentId();

	const [{ commentsDataMap }, { addNewCommentThreads, setCommentsDataMap, clearRemovedComments }] =
		useCommentsData();
	const [{ readCommentsListState }] = useUnreadComments();
	const [{ annotations }] = useEditorAnnotations();
	const pageMode = useGetPageMode();
	const { userId: currentUserId } = useSessionData();
	const [
		{ initialDataLoadedForViewMap, showInlineComments, showGeneralComments },
		{ setInitialDataLoadedForView },
	] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[ViewValues.RESOLVED];

	const annotationsInEditorDoc = useMemo(() => {
		return new Set(annotations);
	}, [annotations]);

	/* eslint-disable react-hooks/rules-of-hooks */
	const {
		loading: queryLoading,
		error,
		refetch,
	} = fg('confluence_frontend_comments_panel_v2')
		? useResolvedCommentsQuery({ pageId: contentId || '' })
		: // eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			useQuery<ResolvedInlineCommentsQueryType>(ResolvedInlineCommentsQuery, {
				variables: {
					pageId: contentId,
					contentStatus: ['DRAFT', 'CURRENT'],
				},
				onCompleted: (data: ResolvedInlineCommentsQueryType) => {
					const hasAlreadyFetchedInitialData = Object.keys(commentsDataMap).length > 0;
					updateCommentsDataState({
						data: data as any, // Just to coerce this type
						readCommentsListState,
						updateData: hasAlreadyFetchedInitialData ? addNewCommentThreads : setCommentsDataMap,
						annotationsInEditorDoc,
						pageMode,
						currentUserId: currentUserId ?? '',
						isOpen: false,
					});

					setLoading(false);
				},
				onError: () => {
					setLoading(false);
				},
			});
	/* eslint-enable react-hooks/rules-of-hooks */

	useEffect(() => {
		if (!fg('confluence_frontend_comments_panel_v2')) {
			if (queryLoading) {
				setLoading(true);
			}
		} else {
			setLoading(queryLoading);
		}
	}, [queryLoading, setLoading]);

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: ViewValues.RESOLVED });
		}
	}, [loading, setInitialDataLoadedForView]);

	useEffect(() => {
		// When this view unmounts, we want to clear out the "resolved"/"deleted" comments
		return () => {
			clearRemovedComments();
		};
	}, [clearRemovedComments]);

	const resolvedThreads = useMemo(() => {
		if (!isInitialCommentDataLoaded && loading) {
			return [];
		}

		return getResolvedCommentThreads({
			commentsDataMap,
			showInlineComments,
			showGeneralComments,
		});
	}, [
		loading,
		isInitialCommentDataLoaded,
		commentsDataMap,
		showInlineComments,
		showGeneralComments,
	]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <Loading />;
		}

		if (error) {
			return (
				<ErrorPanel
					error={error}
					onRetryClick={async () => {
						await refetch();
					}}
				/>
			);
		}
	}

	if (resolvedThreads.length === 0) {
		return (
			<EmptyStateComponent
				SVG={EmptyResolvedSVG}
				emptyCommentsHeaderText={formatMessage(i18n.emptyCommentsHeaderText)}
				emptyCommentsBodyText={formatMessage(i18n.emptyCommentsBodyText)}
			/>
		);
	}

	return (
		<CommentsPanelList
			commentThreads={resolvedThreads}
			supportedTopLevelActions={['reopen']}
			flags={flags}
		/>
	);
};
