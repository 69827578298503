import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import CommentIcon from '@atlaskit/icon/core/comment';
import { Box, xcss } from '@atlaskit/primitives';

const i18n = defineMessages({
	commentPanelHeaderIconLabel: {
		id: 'comments-panel.comments.panel.header-icon.label',
		defaultMessage: 'Comments',
		description: 'Label for the icon on the comment panel header label',
	},
});

const commentsPanelHeaderIconStyle = xcss({
	paddingTop: 'space.050',
});

export const CommentsPanelHeaderIcon = () => {
	const { formatMessage } = useIntl();

	return (
		<Box data-testId="comments-panel-header-icon" xcss={commentsPanelHeaderIconStyle}>
			<CommentIcon label={formatMessage(i18n.commentPanelHeaderIconLabel)} />
		</Box>
	);
};
