/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { AnnotationTypes } from '@atlaskit/adf-schema';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import { xcss, Box, Text, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import PageIcon from '@atlaskit/icon/core/page';

import type { CommentLocation } from '@confluence/inline-comments-queries';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import { CommentType, CommentActionType, type CommentData } from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { usePageInfo } from '@confluence/page-info';
import type { FlagsStateContainer } from '@confluence/flags';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useAddCommentPermissionCheck } from '@confluence/comments-hooks';
import { useCommentsPanel, ViewValues } from '@confluence/comments-panel-utils';

import {
	type CommentThreadMouseEnterParams,
	handleCommentThreadMouseEnter,
	handleCommentThreadMouseLeave,
	handleCommentThreadSelection,
} from '../helper/commentThreadHelper';

import { CommentReplies } from './CommentReplies';
import { Comment } from './Comment';

export type CommentThreadProps = {
	parentComment: CommentData & {
		isFirstPageComment?: boolean;
	};
	supportedTopLevelActions: CommentAction[];
	eventEmitter: AnnotationUpdateEmitter;
	pageMode: PageMode;
	flags?: FlagsStateContainer;
	shouldRenderCommentThreadSeperator?: boolean;
	shouldRenderCommentTypeSeparator?: boolean;
};

const dividerStyle = xcss({
	borderBottom: `1px solid ${token('color.border')}`,
	position: 'relative',
	top: 'space.200',
});

const commentThreadBoxStyles = xcss({
	listStyle: 'none',
	position: 'relative',
	paddingLeft: 'space.200',
	paddingRight: 'space.250',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	margin: '0',
	display: 'flex',
	flexDirection: 'column',
});

const newCommentThreadBoxStyles = xcss({
	cursor: 'pointer',
});

const commentTypeSeperatorStyle = xcss({
	display: 'flex',
	gap: 'space.050',
	paddingTop: 'space.300',
	paddingRight: 'space.300',
	paddingBottom: 'space.150',
	paddingLeft: 'space.200',
	borderTop: `1px solid ${token('color.border')}`,
});

const commentBodyBoxStyles = xcss({
	listStyle: 'none',
	position: 'relative',
	marginRight: 'space.100',
});

const isCurrentSelectedCommentStyles = xcss({
	background: token('color.background.selected'),
});

const isCurrentUserSelectedCommentStyles = xcss({
	background: token('color.background.input.hovered'),
});

const isThreadHoveredStyles = xcss({
	background: token('elevation.surface.hovered'),
});

const isThreadPressedStyles = xcss({
	background: token('color.background.input.hovered'),
});

const i18n = defineMessages({
	commentTypeSeperatorText: {
		id: 'comments-panel.comment.thread.type-seperator',
		defaultMessage: 'General comments',
		description: 'Text for the seperator of inline and general comment section',
	},
});

// This component contains parent comment and its replies
export const CommentThread = ({
	parentComment,
	supportedTopLevelActions,
	eventEmitter,
	pageMode,
	flags,
	shouldRenderCommentThreadSeperator,
	shouldRenderCommentTypeSeparator,
}: CommentThreadProps) => {
	const { formatMessage } = useIntl();

	const [
		{ currentlySelectedCommentMarkerRef, commentsMarkedAsRead, currentView },
		{ setCurrentlySelectedCommentMarkerRef, setCommentsMarkedAsRead },
	] = useCommentsPanel();
	const { pageInfo } = usePageInfo();

	const threadKey =
		parentComment.type === CommentType.INLINE
			? (parentComment.location as CommentLocation).inlineMarkerRef ?? ''
			: parentComment.id;

	const selectedAnnotation = {
		id: threadKey || '',
		type: AnnotationTypes.INLINE_COMMENT,
	};

	// State
	const [isThreadHovered, setIsThreadHovered] = useState<boolean>(false);
	const [isThreadPressed, setIsThreadPressed] = useState<boolean>(false);
	const [hoveredCommentId, setHoveredCommentId] = useState<string | undefined>(undefined);
	const [isAnnotatedTextPressed, setAnnotatedTextPressed] = useState(false);

	// Hook dependent constants
	const isCurrentSelectedComment = currentlySelectedCommentMarkerRef === threadKey;

	const { editCommentId: editCommentQueryId } = useInlineCommentQueryParams();

	const { canAddComments } = useAddCommentPermissionCheck(pageInfo?.id ?? '', true);

	const handleCommentThreadHoverProps: CommentThreadMouseEnterParams = useMemo(
		() => ({
			threadKey,
			setIsThreadHovered,
			setHoveredCommentId,
			pageMode,
			eventEmitter,
		}),
		[threadKey, setIsThreadHovered, setHoveredCommentId, pageMode, eventEmitter],
	);

	const handleMouseEnter = useCallback(
		(id?: string) => {
			handleCommentThreadMouseEnter({
				...handleCommentThreadHoverProps,
				id,
			});
		},
		[handleCommentThreadHoverProps],
	);

	const handleMouseLeave = useCallback(() => {
		handleCommentThreadMouseLeave(handleCommentThreadHoverProps);
	}, [handleCommentThreadHoverProps]);

	const getBackgroundStyle = () => {
		if (isCurrentSelectedComment && !fg('confluence_frontend_comments_panel_v2')) {
			return isCurrentSelectedCommentStyles;
		}
		if (isCurrentSelectedComment && fg('confluence_frontend_comments_panel_v2')) {
			return isCurrentUserSelectedCommentStyles;
		}
		if (isThreadHovered && !fg('confluence_frontend_comments_panel_v2')) {
			return isThreadHoveredStyles;
		}
		if (isThreadPressed && !isAnnotatedTextPressed && fg('confluence_frontend_comments_panel_v2')) {
			return isThreadPressedStyles;
		}
		return undefined;
	};

	const userActionBackgroundStyles = getBackgroundStyle();

	const unreadParentCommentAlreadyMarkedAsReadRef = useRef(false);

	if (
		isCurrentSelectedComment &&
		!unreadParentCommentAlreadyMarkedAsReadRef.current &&
		parentComment.isUnread
	) {
		setCommentsMarkedAsRead({
			threadKey,
			commentIds: [parentComment.id],
			commentType: parentComment.type,
		});
		unreadParentCommentAlreadyMarkedAsReadRef.current = true;
	}

	const isCommentRemovedByAnotherUser = parentComment?.wasRemovedByAnotherUser;
	const isResolvedByAnotherUser =
		isCommentRemovedByAnotherUser &&
		isCommentRemovedByAnotherUser === CommentActionType.RESOLVE_COMMENT_THREAD;

	// if comment thread is resolved, mark it as read exactly once
	useEffect(() => {
		if (isResolvedByAnotherUser) {
			setCommentsMarkedAsRead({
				threadKey,
				commentIds: [parentComment.id, ...parentComment.replies.map((r) => r.id)],
				commentType: parentComment.type,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCommentTheadMouseDown = () => {
		setIsThreadPressed(true);
		setCurrentlySelectedCommentMarkerRef(threadKey);
	};

	const canAddCommentsToCommentThread = canAddComments && currentView !== ViewValues.RESOLVED;

	return (
		<>
			{shouldRenderCommentTypeSeparator &&
				fg('confluence-frontend-comments-panel-design-update') && (
					<Flex
						xcss={commentTypeSeperatorStyle}
						as="li"
						testId="comment-section-seperator"
						alignItems="center"
					>
						<PageIcon
							testId="general-comment-page-icon"
							color={token('color.icon.subtlest')}
							label={formatMessage(i18n.commentTypeSeperatorText)}
						/>
						<Text color="color.text.subtlest" weight="medium">
							{formatMessage(i18n.commentTypeSeperatorText)}
						</Text>
					</Flex>
				)}
			{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
			<Box
				id={`comment-thread-${threadKey}-container`}
				testId={`comment-thread-${threadKey}-container`}
				xcss={[
					commentThreadBoxStyles,
					userActionBackgroundStyles,
					fg('confluence_frontend_comments_panel_v2') && newCommentThreadBoxStyles,
				]}
				as="li"
				onClick={() => {
					handleCommentThreadSelection({
						parentComment,
						pageMode,
						setCurrentlySelectedCommentMarkerRef,
						eventEmitter,
					});
				}}
				onMouseDown={handleCommentTheadMouseDown}
				onMouseUp={() => setIsThreadPressed(false)}
			>
				<Box
					testId={`comment-thread-${parentComment.id}-parent`}
					xcss={commentBodyBoxStyles}
					onMouseEnter={() =>
						fg('confluence_frontend_comments_panel_v2')
							? handleMouseEnter(parentComment.id)
							: undefined
					}
					onMouseLeave={fg('confluence_frontend_comments_panel_v2') ? handleMouseLeave : undefined}
				>
					<Comment
						comment={parentComment}
						supportedTopLevelActions={supportedTopLevelActions}
						pageInfo={pageInfo}
						pageMode={pageMode}
						isUnread={
							!commentsMarkedAsRead[parentComment.type][threadKey]?.has(parentComment.id) &&
							parentComment.isUnread
						}
						isHovered={hoveredCommentId === parentComment.id}
						threadKey={threadKey}
						flags={flags}
						isAnnotatedTextPressed={isAnnotatedTextPressed}
						setAnnotatedTextPressed={setAnnotatedTextPressed}
						hideBranchingStyle={!parentComment.isOpen && parentComment.replies.length === 0}
						canAddComments={canAddCommentsToCommentThread}
						isThreadHovered={isThreadHovered}
					/>
				</Box>
				<CommentReplies
					parentComment={parentComment}
					pageInfo={pageInfo}
					isCurrentSelectedComment={isCurrentSelectedComment}
					hoveredCommentId={hoveredCommentId ?? ''}
					selectedAnnotation={selectedAnnotation}
					editCommentQueryId={editCommentQueryId ?? ''}
					pageMode={pageMode}
					parentCommentId={parentComment.id}
					handleMouseEnter={handleMouseEnter}
					handleMouseLeave={handleMouseLeave}
					canAddComments={canAddCommentsToCommentThread}
				/>
				{/* per new design, we don't need to show the divider, clean up later */}
				{shouldRenderCommentThreadSeperator &&
					!fg('confluence-frontend-comments-panel') &&
					!fg('confluence_frontend_comments_panel_v2') && (
						<Box xcss={dividerStyle} testId={`comment-thread-${parentComment.id}-bottom-divider`} />
					)}
			</Box>
		</>
	);
};
